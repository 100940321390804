import { QueryHookOptions, QueryResult, useContentstackQuery } from '@sigfig/digital-wealth-core';

import { GetHeaderContent, GetHeaderContentVariables } from './__generated__/getHeaderContent.v2';
import * as query from './getHeaderContent.gql';

export const useGetHeaderContent = (
  options?: QueryHookOptions<GetHeaderContent, GetHeaderContentVariables>,
): QueryResult<GetHeaderContent, GetHeaderContentVariables> => {
  return useContentstackQuery(query.GetHeaderContent, options);
};
