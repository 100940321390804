import React, { createContext, useContext, useState } from 'react';
import { SetStateAction } from 'react';
import { Dispatch } from 'react';

const accountNumberContext = createContext<
  { accountNumber: string | undefined; setAccountNumber: Dispatch<SetStateAction<string | undefined>> } | undefined
>(undefined);

export const useAccountNumberContext = () => {
  const context = useContext(accountNumberContext);
  if (context === undefined) {
    throw new Error('useAccountNumberContext must be used within a AccountNumberProvider');
  }
  return context;
};

export const AccountNumberProvider: React.FC<{ value?: string }> = ({ children, value }) => {
  const [accountNumber, setAccountNumber] = useState(value);
  return (
    <accountNumberContext.Provider value={{ accountNumber, setAccountNumber }}>
      {children}
    </accountNumberContext.Provider>
  );
};
