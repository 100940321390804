import * as Sentry from '@sentry/browser';
import React, { FC, lazy, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import {
  ApolloProvider,
  cacheTypePolicies as symphonyCacheTypePolicies,
  getApolloClient,
  getContentstackHttpLinkConfig,
  getGoalsHttpLinkConfig,
  HttpLink,
  InMemoryCache,
  onError,
  setContext,
  sfPosthog,
  useLocale,
  usePostHog,
} from '@sigfig/digital-wealth-core';

import { KeepAlive } from '../auth/KeepAlive';
import { PageLoading } from '../components/Loading';
import config from '../config';
import { useAuth0 } from '../hooks/auth0';

import { AppLayout } from './AppLayout';

const ProtectedRoute = lazy(() => import('../auth/ProtectedRoute'));

export const AuthedApp: FC = () => {
  const { isLoading, token, user } = useAuth0();
  const [initialized, setInitialized] = useState(false);
  const [, setLocale] = useLocale();
  const posthog = usePostHog();
  sfPosthog.useCaptureSpaPageViews(useLocation());

  useEffect(() => {
    if (user && !initialized) {
      setLocale(user.locale);
      setInitialized(true);
    }
  }, [initialized, setLocale, user]);

  useEffect(() => {
    if (user?.inContextPartyId) {
      Sentry.setUser({ id: user.inContextPartyId });
      posthog?.identify(user.inContextPartyId);
    }
  }, [posthog, user?.inContextPartyId]);

  const symphonyAuthLink = setContext((_, { headers }) => ({
    headers: {
      ...headers,
      authorization: user?.jwt ? `Bearer ${user.jwt}` : '',
    },
  }));

  const SymphonyLogLink = onError(({ operation, graphQLErrors, networkError }) => {
    Sentry.withScope(scope => {
      const context = operation.getContext();
      scope.setTags({
        'sigfig-correlation-id': context.response?.headers?.get('sigfig-correlation-id'),
        'sigfig-multisession-id': context.response?.headers?.get('sigfig-multisession-id'),
      });
      scope.setContext('operation', {
        ...operation,
      });
      if (graphQLErrors) {
        scope.setContext(
          'graphql Errors',
          graphQLErrors.reduce((acc, e, i) => ({ ...acc, [i]: JSON.stringify(e) }), {}),
        );
      }
      if (networkError) {
        scope.setContext('network error', {
          obj: JSON.stringify(networkError),
        });
      }

      Sentry.captureMessage(`Symphony Error - ${operation.operationName}`, 'error');
    });
  });

  const getSymphonyHttpLinkConfig = (uri: string) => ({
    name: 'symphony',
    link: symphonyAuthLink.concat(SymphonyLogLink).concat(
      new HttpLink({
        uri,
        fetch,
      }),
    ),
  });

  const symphonyUrl = '/symphony/ui/graphql';
  const goalsUrl = config.goals.url;
  const client = getApolloClient({
    links: [
      getGoalsHttpLinkConfig(goalsUrl, token ?? ''),
      getContentstackHttpLinkConfig(config.contentstack.environment, config.contentstack.deliveryToken),
      getSymphonyHttpLinkConfig(symphonyUrl),
    ],
    cache: new InMemoryCache({
      typePolicies: {
        ...symphonyCacheTypePolicies,
      },
    }),
  });

  if (isLoading) {
    return <PageLoading />;
  }

  return (
    <ProtectedRoute
      component={() => (
        <ApolloProvider client={client}>
          <KeepAlive>
            <AppLayout>
              <Outlet />
            </AppLayout>
          </KeepAlive>
        </ApolloProvider>
      )}
    />
  );
};
