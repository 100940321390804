import React, { lazy } from 'react';
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router-dom';

import { AuthedApp } from './AuthedApp';
import { AuthWrapper } from './AuthWrapper';
import { PageRoute, routes } from './routes';

const AccountAlreadyInProgress = lazy(() => import('../containers/AccountAlreadyInProgress'));
const AccountDetails = lazy(() => import('../containers/AccountDetails'));
const Dashboard = lazy(() => import('../containers/Dashboard'));
const ExpiredProfile = lazy(() => import('../containers/ExpiredProfile'));
const GoalsCreate = lazy(() => import('../containers/Goals/Create'));
const GoalsEdit = lazy(() => import('../containers/Goals/Edit'));
const Onboarding = lazy(() => import('../containers/Onboarding'));
const RetakeWrapper = lazy(() => import('./RetakeWrapper'));

const router = createBrowserRouter([
  {
    element: (
      <AuthWrapper>
        <Outlet />
      </AuthWrapper>
    ),
    children: [
      {
        element: <AuthedApp />,
        children: [
          {
            element: <AccountAlreadyInProgress />,
            path: PageRoute.AccountAlreadyInProgress,
          },
          {
            element: <AccountDetails />,
            path: PageRoute.AccountDetails,
          },
          {
            element: <Dashboard />,
            path: PageRoute.AccountSummary,
          },
          {
            element: <Dashboard />,
            path: PageRoute.Goals,
          },
          {
            element: <GoalsCreate />,
            path: PageRoute.GoalsCreate,
          },
          {
            element: <GoalsEdit />,
            path: PageRoute.GoalsEdit,
          },
          {
            element: <Onboarding />,
            path: PageRoute.Onboarding,
          },
          {
            element: <Onboarding />,
            path: PageRoute.OnboardingStep,
          },
          {
            element: <ExpiredProfile />,
            path: PageRoute.ExpiredProfile,
          },
          {
            element: <RetakeWrapper />,
            path: PageRoute.Retake,
          },
          {
            element: <Navigate to={routes.accountSummary()} />,
            path: '*',
          },
        ],
      },
    ],
  },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
