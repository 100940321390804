/* eslint-disable @typescript-eslint/no-empty-function */
import * as Sentry from '@sentry/react';
import axios from 'axios';
import debounce from 'lodash.debounce';
import React, { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { SessionTimeoutMessage } from '@sigfig/digital-wealth-core';

import { routes } from '../../App/routes';
import { useApp } from '../../contexts/App';
import { useAuth0 } from '../../hooks/auth0';

interface Props {
  children: ReactNode;
}

export const KeepAlive: React.FC<Props> = ({ children }) => {
  const { contentOptions } = useApp();
  const { user, logout } = useAuth0();
  const [inContextPartyId, setInContextPartyId] = useState('');
  const timeoutMessageRef = useRef<{ resetSession: () => void }>(null);
  const navigate = useNavigate();

  const callKeepAlive = useCallback(async () => {
    if (user?.jwt) {
      try {
        await axios({
          method: 'post',
          url: '/symphony/auth/v1/keep-alive',
          headers: {
            Authorization: `Bearer ${user.jwt}`,
          },
        });
        // on any session refresh, ensure to reset the timeout message to start over again.
        timeoutMessageRef.current?.resetSession();
      } catch (e) {
        Sentry.captureException(e);
        console.error(e);
      }
    }
  }, [user?.jwt]);

  useEffect(() => {
    const contextPartyId = user?.inContextPartyId;
    if (contextPartyId && inContextPartyId !== contextPartyId) {
      setInContextPartyId(contextPartyId);
    }
  }, [user, inContextPartyId]);

  useEffect(() => {
    ['click', 'wheel'].forEach(event => window.addEventListener(event, debounce(callKeepAlive, 1000)));
    return () => {
      ['click', 'wheel'].forEach(event => window.removeEventListener(event, debounce(callKeepAlive, 1000)));
    };
  }, [callKeepAlive]);

  return (
    <>
      {children}
      <SessionTimeoutMessage
        contentOptions={contentOptions}
        logoutCallback={() => {
          setTimeout(() => {
            logout({ returnTo: window.location.origin });
          }, 5000);
        }}
        navigateCallback={() => navigate(routes.accountSummary())}
        ref={timeoutMessageRef}
        refreshCallback={callKeepAlive}
        timeOutInSeconds={1800}
        timeoutThreshold={300}
      />
    </>
  );
};
