// @ts-ignore noUnusedLocals
// @generated This file was automatically generated and should not be edited.
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: string;
  DateTime: string;
  Decimal: string;
  Upload: File;
}

export enum AccountHolderType {
  AUTHORIZED_INDIVIDUAL = 'AUTHORIZED_INDIVIDUAL',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  CONTROL_PERSON = 'CONTROL_PERSON'
}

export enum AccountRestrictionPlacedBy {
  CUSTODIAL = 'CUSTODIAL',
  PARTNER_OPS = 'PARTNER_OPS',
  UNKNOWN = 'UNKNOWN'
}

export enum AccountRestrictionType {
  BILLING = 'BILLING',
  CLOSING_TRADES_ONLY = 'CLOSING_TRADES_ONLY',
  EDUCATIONAL_ASSETS_ONLY = 'EDUCATIONAL_ASSETS_ONLY',
  LIQUIDATING_TRADES_ONLY = 'LIQUIDATING_TRADES_ONLY',
  MONEY_IN = 'MONEY_IN',
  MONEY_OUT = 'MONEY_OUT',
  REGULATION_T = 'REGULATION_T',
  UNKNOWN = 'UNKNOWN',
  UNKNOWN_RESTRICTION = 'UNKNOWN_RESTRICTION'
}

export interface AddressInput {
  addressLine1?: InputMaybe<Scalars['String']>;
  addressLine2?: InputMaybe<Scalars['String']>;
  countryCode?: InputMaybe<Scalars['String']>;
  /** This refers to the state in a country */
  countryPrimarySubdivision?: InputMaybe<Scalars['String']>;
  /** This refers to the city in a country */
  countrySecondarySubdivision?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  postalCode?: InputMaybe<Scalars['String']>;
  type: AddressType;
}

export enum AddressType {
  HOME = 'HOME',
  MAILING = 'MAILING',
  OFFICE = 'OFFICE',
  OTHER = 'OTHER'
}

export interface AdvisorMetricSortInput {
  /** Metric ID to sort advisors by */
  metricId: Scalars['String'];
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum AssetDepositField {
  CREATED = 'CREATED'
}

export enum AssetDepositResolvedTransferMethod {
  ASSET_DEPOSIT_EXTERNAL = 'ASSET_DEPOSIT_EXTERNAL',
  ASSET_DEPOSIT_INTERNAL = 'ASSET_DEPOSIT_INTERNAL'
}

export interface AssetDepositsSortInput {
  /** Fields that support sorting */
  field: AssetDepositField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export interface AssetFundingOptionAdditionalAttributesInput {
  assetTransferTransactionType?: InputMaybe<AssetTransferTransactionType>;
}

export interface AssetFundingOptionEntity {
  entityAttributes: AssetFundingOptionAdditionalAttributesInput;
  entityId: Scalars['ID'];
}

export interface AssetFundingOptionOwnerInput {
  accountProfileId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
  ownerDetails?: InputMaybe<PartyInput>;
  ownerType: AssetFundingOptionOwnerType;
  source: AssetFundingOptionOwnerSource;
  ssn?: InputMaybe<Scalars['String']>;
}

export enum AssetFundingOptionOwnerSource {
  CUSTODIAN = 'CUSTODIAN',
  MANUAL = 'MANUAL'
}

export enum AssetFundingOptionOwnerType {
  CUSTODIAN = 'CUSTODIAN',
  DECEDENT = 'DECEDENT',
  FIRST_PARTY = 'FIRST_PARTY',
  THIRD_PARTY = 'THIRD_PARTY'
}

export interface AssetInformationInput {
  assets?: InputMaybe<Array<AssetsInput>>;
  availableTopics?: InputMaybe<Array<AvailableTopics>>;
  liabilities?: InputMaybe<Array<LiabilitiesInput>>;
}

/** Asset proportion types represent a logical bucket of comparing asset allocation (i.e Domestic, Emerging) */
export enum AssetProportionType {
  /** The assets belonging to domestic markets */
  DOMESTIC_MARKET = 'DOMESTIC_MARKET',
  /** The assets belonging to emerging markets */
  EMERGING_MARKET = 'EMERGING_MARKET',
  /** The assets belonging to international markets */
  INTERNATIONAL_MARKET = 'INTERNATIONAL_MARKET',
  UNKNOWN_ASSET_PROPORTION_TYPE = 'UNKNOWN_ASSET_PROPORTION_TYPE'
}

export interface AssetTransferAttributesInput {
  assetFundingOptionId?: InputMaybe<Scalars['ID']>;
  assetTransferTransactionType?: InputMaybe<AssetTransferTransactionType>;
  dataSourceCode?: InputMaybe<Scalars['String']>;
  federalTaxWithholdingCode?: InputMaybe<Scalars['String']>;
  federalTaxWithholdingPercentage?: InputMaybe<Scalars['Decimal']>;
  isMutualFundCompany?: InputMaybe<Scalars['Boolean']>;
  isSwitchFormRequired?: InputMaybe<Scalars['Boolean']>;
  morningstarProposalId?: InputMaybe<Scalars['String']>;
  skipCreationAtBrokerage?: InputMaybe<Scalars['Boolean']>;
  stateTaxWithholdingCode?: InputMaybe<Scalars['String']>;
  stateTaxWithholdingPercentage?: InputMaybe<Scalars['Decimal']>;
  vendorCode?: InputMaybe<Scalars['String']>;
  withdrawalGrossUp?: InputMaybe<Scalars['Boolean']>;
}

export interface AssetTransferEntity {
  entityAttributes: AssetTransferAttributesInput;
  entityId: Scalars['ID'];
}

export enum AssetTransferTransactionType {
  FIRST_PARTY = 'FIRST_PARTY',
  THIRD_PARTY = 'THIRD_PARTY',
  THIRD_PARTY_COMMON = 'THIRD_PARTY_COMMON'
}

export interface AssetsInput {
  cars?: InputMaybe<Scalars['Decimal']>;
  checkingAccounts?: InputMaybe<Scalars['Decimal']>;
  investmentAccounts?: InputMaybe<Scalars['Decimal']>;
  other?: InputMaybe<Scalars['Decimal']>;
  realEstate?: InputMaybe<Scalars['Decimal']>;
  savingsAccounts?: InputMaybe<Scalars['Decimal']>;
}

export enum AssociatedAccountType {
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BROKERAGE_ACCOUNT = 'BROKERAGE_ACCOUNT',
  NO_ASSOCIATION = 'NO_ASSOCIATION'
}

export interface AssociatedEntityInput {
  entityId: Scalars['ID'];
  entityType: AssociatedEntityType;
}

export enum AssociatedEntityType {
  ASSET_DEPOSIT = 'ASSET_DEPOSIT',
  BANK_ACCOUNT_ASSOCIATION = 'BANK_ACCOUNT_ASSOCIATION',
  ENTITY_UPDATE_WORKFLOW = 'ENTITY_UPDATE_WORKFLOW',
  PLAN_UPDATE_WORKFLOW = 'PLAN_UPDATE_WORKFLOW'
}

export enum AvailableTopics {
  ASSET_PROTECTION = 'ASSET_PROTECTION',
  BENEFICIARIES = 'BENEFICIARIES',
  DEBT_CONSOLIDATION = 'DEBT_CONSOLIDATION',
  ELDER_CARE = 'ELDER_CARE',
  HOME_EQUITY = 'HOME_EQUITY',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  RETIREMENT = 'RETIREMENT',
  SAVING_FOR_A_CAR = 'SAVING_FOR_A_CAR',
  SAVING_FOR_COLLEGE = 'SAVING_FOR_COLLEGE'
}

export interface BalanceInput {
  /** The monetary amount */
  balance: MoneyInput;
  /** The type of balance (i.e CASH, AVAILABLE) */
  type: BalanceType;
}

export enum BalanceType {
  AVAILABLE = 'AVAILABLE',
  CASH = 'CASH',
  CASH_AVAILABLE = 'CASH_AVAILABLE',
  CASH_TOTAL = 'CASH_TOTAL',
  MARGIN = 'MARGIN',
  MARGIN_ACCOUNT_BUYING_POWER = 'MARGIN_ACCOUNT_BUYING_POWER',
  POSITIONS_TOTAL = 'POSITIONS_TOTAL',
  SHORT = 'SHORT',
  TOTAL_ACCOUNT = 'TOTAL_ACCOUNT',
  TOTAL_TRADE_DATE = 'TOTAL_TRADE_DATE',
  TYPE_5 = 'TYPE_5',
  /** Unknown balance type */
  UNKNOWN_BALANCE = 'UNKNOWN_BALANCE',
  WITHDRAWAL_LIMIT = 'WITHDRAWAL_LIMIT'
}

export interface BankAccountInput {
  accountNumber: Scalars['String'];
  attributes?: InputMaybe<Array<StringAttributesInput>>;
  financialInstitution: Scalars['String'];
  isSynced?: InputMaybe<Scalars['Boolean']>;
  nameOnBankAccount: Scalars['String'];
  productName?: InputMaybe<Scalars['String']>;
  routingNumber: Scalars['String'];
  type: BankAccountType;
}

export interface BankAccountInputWithParty {
  bankAccount: BankAccountInput;
  partyId: Scalars['ID'];
}

export enum BankAccountType {
  CHEQUING = 'CHEQUING',
  SAVINGS = 'SAVINGS'
}

export enum BillingRateTargetType {
  /** Partner Fee */
  ADVISOR = 'ADVISOR',
  /** Platform Fee */
  PLATFORM = 'PLATFORM',
  /** Provider Fee */
  PROVIDER = 'PROVIDER',
  /** Unrecognized configId is passed */
  UNKNOWN = 'UNKNOWN'
}

export enum BillingRateType {
  /** This is applied accross all tiers the same way. Regardless of AUM. 10% is 10% */
  ANNUALIZED_ABSOLUTE = 'ANNUALIZED_ABSOLUTE',
  /** This is applied in a non marginal manner. IE: for tier 0 - 100K = 1%. if a CP has 99K => 1% on complete value */
  ANNUALIZED_NON_MARGINAL_TIERED = 'ANNUALIZED_NON_MARGINAL_TIERED',
  /** This is applied in a marginal manner. IE: AUM = 25,000. Teir 1 rate: 0.012, Tier 2 rate: 0.09. Tier 1 upperBounds 15,000. First 15,000 of AUM charged at 12%. Second 10,000 charged at 9% */
  ANNUALIZED_PERCENTAGE = 'ANNUALIZED_PERCENTAGE'
}

export enum BlockType {
  /** Hard blocks present a warning and prevent a user from continuing when triggered. */
  HARD = 'HARD',
  /** Soft blocks present a warning but do not prevent a user from continuing when triggered. */
  SOFT = 'SOFT'
}

export interface BusinessEntityAttributesInput {
  accountHolderType?: InputMaybe<Array<AccountHolderType>>;
  countryOfTaxation?: InputMaybe<Scalars['String']>;
  organizationDocumentName?: InputMaybe<Scalars['String']>;
  resolutionDateAdopted?: InputMaybe<Scalars['Date']>;
  roleType?: InputMaybe<Array<InputMaybe<BusinessEntityRoleType>>>;
  taxation?: InputMaybe<Scalars['String']>;
  trustDate?: InputMaybe<Scalars['Date']>;
}

export interface BusinessEntityInput {
  attributes?: InputMaybe<BusinessEntityAttributesInput>;
  country?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  state?: InputMaybe<Scalars['String']>;
}

export enum BusinessEntityRoleType {
  ACCREDITED_INVESTOR = 'ACCREDITED_INVESTOR',
  US_REGISTERED_BROKER_DEALER = 'US_REGISTERED_BROKER_DEALER',
  US_REGISTERED_INVESTMENT_ADVISOR = 'US_REGISTERED_INVESTMENT_ADVISOR',
  US_REGISTERED_INVESTMENT_COMPANY = 'US_REGISTERED_INVESTMENT_COMPANY'
}

export enum BusinessEntityType {
  COMPANY = 'COMPANY',
  HOUSEHOLD = 'HOUSEHOLD',
  TRUST = 'TRUST',
  UNKNOWN = 'UNKNOWN'
}

export interface CancelTransferInput {
  brokerageTransferId?: InputMaybe<Scalars['String']>;
  /** This represents the managedProductId the transfer was created under. */
  financialAccountId: Scalars['ID'];
  frequency: TransferFrequency;
  pin?: InputMaybe<Scalars['String']>;
  /** The id for the cash transfer to cancel. */
  transferId: Scalars['ID'];
  type: TransferType;
}

export enum CashReallocationStatus {
  CANCELLED = 'CANCELLED',
  COMPLETE = 'COMPLETE',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING'
}

export enum CashTransferContributionType {
  ANNUAL_IRA_CONTRIBUTION = 'ANNUAL_IRA_CONTRIBUTION',
  EMPLOYER_CONTRIBUTION = 'EMPLOYER_CONTRIBUTION'
}

export enum CashTransferInstanceStatus {
  CANCELLED = 'CANCELLED',
  CASH_GENERATED = 'CASH_GENERATED',
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  TRADES_SUBMITTED = 'TRADES_SUBMITTED',
  UNKNOWN = 'UNKNOWN'
}

export interface ClientDocumentListPaginationInput {
  /** Limit */
  limit?: InputMaybe<Scalars['Int']>;
  /** Offset map for that implementation */
  offset?: InputMaybe<Array<OffsetInput>>;
}

export enum ClientReviewStatus {
  BAD_EMAIL = 'BAD_EMAIL',
  DUE = 'DUE',
  NEW = 'NEW',
  OVERDUE = 'OVERDUE',
  PENDING = 'PENDING',
  /** Unknown review status type */
  UNKNOWN = 'UNKNOWN',
  UP_TO_DATE = 'UP_TO_DATE'
}

export enum CloseAccountStatus {
  CANCELLED = 'CANCELLED',
  CASH_GENERATED = 'CASH_GENERATED',
  COMPLETED = 'COMPLETED',
  IN_PROCESS_AT_BROKER = 'IN_PROCESS_AT_BROKER',
  PENDING = 'PENDING',
  REACHING_OUT_TO_CLIENT = 'REACHING_OUT_TO_CLIENT',
  READY_TO_TRADE = 'READY_TO_TRADE',
  TRADES_SUBMITTED = 'TRADES_SUBMITTED'
}

export enum CloseReason {
  ACCOUNT_HOLDER_HAS_PASSED_AWAY = 'ACCOUNT_HOLDER_HAS_PASSED_AWAY',
  BELOW_TECHNICAL_MINIMUM_BALANCE = 'BELOW_TECHNICAL_MINIMUM_BALANCE',
  CHANGING_TO_A_DIFFERENT_INVESTMENT_SOLUTION = 'CHANGING_TO_A_DIFFERENT_INVESTMENT_SOLUTION',
  FEES_TOO_HIGH = 'FEES_TOO_HIGH',
  I_AM_REALLOCATING_MY_ASSETS = 'I_AM_REALLOCATING_MY_ASSETS',
  I_AM_REBALANCING_MY_ASSETS = 'I_AM_REBALANCING_MY_ASSETS',
  MONEY_NEEDED_FOR_PURCHASE = 'MONEY_NEEDED_FOR_PURCHASE',
  MOVING_MY_INVESTMENTS_TO_ANOTHER_FIRM = 'MOVING_MY_INVESTMENTS_TO_ANOTHER_FIRM',
  MOVING_TO_NEW_ADVISOR = 'MOVING_TO_NEW_ADVISOR',
  NEED_THE_FUNDS_IN_THE_ACCOUNT = 'NEED_THE_FUNDS_IN_THE_ACCOUNT',
  OTHER = 'OTHER',
  PERFORMANCE_NOT_AS_EXPECTED = 'PERFORMANCE_NOT_AS_EXPECTED',
  PREFER_A_BROKERAGE_ACCOUNT = 'PREFER_A_BROKERAGE_ACCOUNT',
  PRODUCT_CONFUSING = 'PRODUCT_CONFUSING',
  PRODUCT_NOT_AS_EXPECTED = 'PRODUCT_NOT_AS_EXPECTED',
  RAISE_CASH = 'RAISE_CASH',
  RECONSIDERING_MY_INVESTMENTS_DUE_TO_THE_CURRENT_MARKET_ENVIRONMENT = 'RECONSIDERING_MY_INVESTMENTS_DUE_TO_THE_CURRENT_MARKET_ENVIRONMENT',
  RETURNS_NOT_AS_EXPECTED = 'RETURNS_NOT_AS_EXPECTED',
  TECH_OR_SIGNUP_ISSUES = 'TECH_OR_SIGNUP_ISSUES',
  UNDERGOING_A_DIVORCE_AND_REQUIRE_DIRECT_ACCESS_TO_ASSETS = 'UNDERGOING_A_DIVORCE_AND_REQUIRE_DIRECT_ACCESS_TO_ASSETS',
  WANT_MORE_DIVERSIFICATION = 'WANT_MORE_DIVERSIFICATION',
  WANT_TO_MANAGE_INVESTMENTS_MYSELF = 'WANT_TO_MANAGE_INVESTMENTS_MYSELF'
}

export enum CloseWorkflowField {
  /** Sort by CloseWorkflow.CreatedAt */
  CREATED = 'CREATED'
}

export interface CloseWorkflowsSortInput {
  /** Fields that support sorting */
  field: CloseWorkflowField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export interface CompanyInput {
  designation?: InputMaybe<Scalars['String']>;
  organizationName?: InputMaybe<Scalars['String']>;
}

export enum CompareTag {
  ONBOARDING = 'ONBOARDING',
  RCE = 'RCE'
}

export enum CompensationTypes {
  BONUS = 'BONUS',
  LIFE_INSURANCE = 'LIFE_INSURANCE',
  PENSION_PLAN = 'PENSION_PLAN',
  RETIREMENT_PLAN_401K = 'RETIREMENT_PLAN_401K',
  STOCK_BASED_COMPENSATION = 'STOCK_BASED_COMPENSATION',
  VARIABLE_COMPENSATION = 'VARIABLE_COMPENSATION'
}

export interface ComputeRecommendedPortfolioAllocationInput {
  /** Model portfolio internal identifier */
  modelPortfolioInternalId: Scalars['Int'];
  /** Model portfolio series id */
  modelPortfolioSeriesId: Scalars['Int'];
  /** Percentage in composite */
  percentage: Scalars['Decimal'];
}

export enum ConditionSetOperationType {
  ADD = 'ADD',
  DIVIDE = 'DIVIDE',
  MULTIPLY = 'MULTIPLY',
  SUBTRACT = 'SUBTRACT'
}

export enum ConditionType {
  AND = 'AND',
  OR = 'OR'
}

export enum ConsumerRoleType {
  CLIENT = 'CLIENT',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  INVESTMENT_SPECIALIST = 'INVESTMENT_SPECIALIST'
}

export enum ContactType {
  DAYTIME_PHONE = 'DAYTIME_PHONE',
  DIGITAL = 'DIGITAL',
  EMAIL = 'EMAIL',
  GENERIC_PHONE = 'GENERIC_PHONE',
  INTERNATIONAL_PHONE = 'INTERNATIONAL_PHONE',
  LANDLINE = 'LANDLINE',
  MOBILE = 'MOBILE',
  TOLL_FREE = 'TOLL_FREE',
  UNKNOWN = 'UNKNOWN'
}

export interface ContentStackEntryInput {
  /** The unique content_type_uid that contentstack uses to identify a content type. */
  contentTypeId: Scalars['ID'];
  /** The unique entry_uid that contentstack uses to identify an entry for a content type. */
  entryId: Scalars['ID'];
  /** The contentstack environment name to fetch entries for. Requires token argument for proper usage. */
  environment?: InputMaybe<Scalars['String']>;
  /** The read only delivery token for fetching contentstack entries. Requires environment argument for proper usage. */
  token?: InputMaybe<Scalars['ID']>;
}

export enum ControlType {
  DIRECTOR = 'DIRECTOR',
  POLICY_MAKING_EXECUTIVE = 'POLICY_MAKING_EXECUTIVE',
  /** 10% Shareholder */
  SHAREHOLDER_10 = 'SHAREHOLDER_10'
}

export enum Country {
  USA = 'USA'
}

export interface CreateAnnuityTransferInput {
  accountNumber: Scalars['String'];
  amount: Scalars['Decimal'];
  deliveryFirm: DeliveryFirmInput;
  fundingSourceIndex?: InputMaybe<Scalars['Int']>;
  managedProductId: Scalars['ID'];
  surrenderType: SurrenderType;
}

export interface CreateAssetTransferInput {
  attributes?: InputMaybe<AssetTransferAttributesInput>;
  cashAmount?: InputMaybe<Scalars['Decimal']>;
  contributionYear?: InputMaybe<Scalars['Int']>;
  destinationFinancialAccountId: Scalars['ID'];
  fundingSourceIndex?: InputMaybe<Scalars['Int']>;
  isFullTransfer?: InputMaybe<Scalars['Boolean']>;
  isInitial?: InputMaybe<Scalars['Boolean']>;
  method: TransferMethod;
  methodSubType?: InputMaybe<TransferMethodSubType>;
  notes?: InputMaybe<Scalars['String']>;
  positionTransfer: PositionTransferInput;
  sourceFinancialAccountAccountNumber?: InputMaybe<Scalars['String']>;
  sourceFinancialAccountBrokerageAlias?: InputMaybe<Scalars['String']>;
  sourceFinancialAccountBrokerageName?: InputMaybe<Scalars['String']>;
  sourceFinancialAccountId?: InputMaybe<Scalars['ID']>;
  sourceFinancialAccountType: FinancialAccountType;
  sourcePortfolioBrokerageDtcNumber?: InputMaybe<Scalars['String']>;
  transferAllCash?: InputMaybe<Scalars['Boolean']>;
  type: TransferType;
}

export interface CreateCashTransferInput {
  bankAccountWithParty?: InputMaybe<BankAccountInputWithParty>;
  cashAmount: Scalars['Decimal'];
  contributionType?: InputMaybe<CashTransferContributionType>;
  contributionYear?: InputMaybe<Scalars['Int']>;
  day?: InputMaybe<Scalars['Int']>;
  destinationFinancialAccountId?: InputMaybe<Scalars['ID']>;
  distributionReason?: InputMaybe<Scalars['String']>;
  federalTaxWithholdingCode?: InputMaybe<Scalars['String']>;
  federalTaxWithholdingPercentage?: InputMaybe<Scalars['Decimal']>;
  frequency: TransferFrequency;
  fundingSourceIndex?: InputMaybe<Scalars['Int']>;
  notes?: InputMaybe<Scalars['String']>;
  numberOfOccurrences?: InputMaybe<Scalars['Int']>;
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
  sleeve: SleeveType;
  sourceFinancialAccountId?: InputMaybe<Scalars['ID']>;
  stateTaxWithholdingCode?: InputMaybe<Scalars['String']>;
  stateTaxWithholdingPercentage?: InputMaybe<Scalars['Decimal']>;
  transactionId?: InputMaybe<Scalars['String']>;
  type: TransferType;
  withdrawalGrossUp?: InputMaybe<Scalars['Boolean']>;
}

export interface CreateRaiseCashAssetTransferInput {
  amount: Scalars['Decimal'];
  attributes?: InputMaybe<AssetTransferAttributesInput>;
  contributionYear?: InputMaybe<Scalars['Int']>;
  destinationManagedProductId: Scalars['ID'];
  isFullTransfer?: InputMaybe<Scalars['Boolean']>;
  isInitial: Scalars['Boolean'];
  nylSourcePortfolioId?: InputMaybe<Scalars['ID']>;
  sourceManagedProductId: Scalars['ID'];
}

export enum CreatedByRole {
  ADVISOR = 'ADVISOR',
  CLIENT = 'CLIENT'
}

/** A list of valid types for creating an account */
export enum CreationType {
  FULL_ACCOUNT_TRANSFER = 'FULL_ACCOUNT_TRANSFER',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
  PARTIAL_ACCOUNT_TRANSFER = 'PARTIAL_ACCOUNT_TRANSFER'
}

export enum CreationWorkflowType {
  FULL_ACCOUNT_TRANSFER = 'FULL_ACCOUNT_TRANSFER',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
  PARTIAL_ACCOUNT_TRANSFER = 'PARTIAL_ACCOUNT_TRANSFER'
}

/** How many records to skip and the total records to return */
export interface CursorPaginationInput {
  /** The last item to appear in the sequence. */
  cursor?: InputMaybe<Scalars['String']>;
  /** The total number of results to return. */
  pageSize: Scalars['Int'];
}

export interface DataPointInput {
  /** The key related to the data point. */
  dataPointKey: Scalars['ID'];
  type: DataPointType;
  values: Array<DataPointValueInput>;
}

/** Defines the data type expected from collected data point */
export enum DataPointType {
  BOOLEAN = 'BOOLEAN',
  CURRENCY = 'CURRENCY',
  INTEGER = 'INTEGER',
  MULTIPLE_OPTION = 'MULTIPLE_OPTION',
  SINGLE_OPTION = 'SINGLE_OPTION'
}

export interface DataPointValueInput {
  value: Scalars['String'];
  /** The displayed text for the value, required for single-option and multiple-option input. */
  valueText?: InputMaybe<Scalars['String']>;
}

export interface DeliveryFirmInput {
  address: AddressInput;
  name: Scalars['String'];
  phoneNumber: Scalars['String'];
}

export enum DocumentCategoryEnum {
  OTHERS = 'OTHERS',
  STATEMENTS = 'STATEMENTS',
  TAX_REPORTS = 'TAX_REPORTS',
  TRADE_CONFIRMATIONS = 'TRADE_CONFIRMATIONS'
}

export interface EmploymentInformationInput {
  availableTopics?: InputMaybe<Array<AvailableTopics>>;
  compensationTypes?: InputMaybe<Array<CompensationTypes>>;
}

export enum EmploymentStatus {
  EMPLOYED = 'EMPLOYED',
  HOME_MAKER = 'HOME_MAKER',
  RETIRED = 'RETIRED',
  SELF_EMPLOYED = 'SELF_EMPLOYED',
  STUDENT = 'STUDENT',
  UNEMPLOYED = 'UNEMPLOYED',
  UNKNOWN = 'UNKNOWN'
}

export interface EntityInput {
  assetFundingOptionEntity?: InputMaybe<AssetFundingOptionEntity>;
  assetTransferEntity?: InputMaybe<AssetTransferEntity>;
  managedProductEntity?: InputMaybe<ManagedProductEntity>;
}

export enum EntityProfileType {
  ENTITY = 'ENTITY',
  STAKEHOLDER_CONTACT = 'STAKEHOLDER_CONTACT',
  STAKEHOLDER_ENTITY = 'STAKEHOLDER_ENTITY'
}

export enum EntityType {
  ASSET_DEPOSIT_EXTERNAL = 'ASSET_DEPOSIT_EXTERNAL',
  ASSET_DEPOSIT_INTERNAL = 'ASSET_DEPOSIT_INTERNAL',
  BANK_ACCOUNT = 'BANK_ACCOUNT',
  BANK_ACCOUNT_ASSOCIATION = 'BANK_ACCOUNT_ASSOCIATION',
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
  COPILOT_PORTFOLIO = 'COPILOT_PORTFOLIO',
  EXTERNAL_REQUEST = 'EXTERNAL_REQUEST',
  INTERNAL_REQUEST = 'INTERNAL_REQUEST',
  RECURRING_CASH_DEPOSIT = 'RECURRING_CASH_DEPOSIT',
  RECURRING_CASH_WITHDRAWAL = 'RECURRING_CASH_WITHDRAWAL',
  SIGNING_DOCUMENT = 'SIGNING_DOCUMENT'
}

export enum Event {
  EXPERIENCE_ENDED = 'EXPERIENCE_ENDED',
  EXPERIENCE_STARTED = 'EXPERIENCE_STARTED'
}

export interface EventMetadataInput {
  /** Redirect URL. */
  redirect?: InputMaybe<Scalars['String']>;
  /** Final State. */
  state?: InputMaybe<Scalars['String']>;
}

export interface FamilyInformationInput {
  age?: InputMaybe<Scalars['Int']>;
  availableTopics?: InputMaybe<Array<AvailableTopics>>;
}

/** Fee category represents the different kinds of fee associated with a financial account */
export enum FeeCategory {
  /** Fees charged by the financial advisor */
  ADVISOR = 'ADVISOR',
  /** Fees charged by the fund manager */
  FUND = 'FUND',
  /** Total Fees */
  TOTAL = 'TOTAL',
  /** Fees charged by the trading institution */
  TRADING = 'TRADING'
}

/** Fee level represents if the fee is the overall aggregate, partial aggregate, or an individual component */
export enum FeeLevel {
  /** The lowest breakdown of a fee */
  INDIVIDUAL_COMPONENT = 'INDIVIDUAL_COMPONENT',
  /** The total fees, aggregating all individual components */
  OVERALL_AGGREGATE = 'OVERALL_AGGREGATE',
  /** An aggregate of selected individual components */
  PARTIAL_AGGREGATE = 'PARTIAL_AGGREGATE'
}

export enum FeesDiscountType {
  ADVISOR = 'ADVISOR',
  ADVISOR_RATE = 'ADVISOR_RATE',
  OTHER = 'OTHER'
}

export enum Field {
  AMOUNT = 'AMOUNT',
  CREATED = 'CREATED',
  /** Sort by date removed for trading suspension history. */
  DATE_ADDED = 'DATE_ADDED',
  /** Sort by date removed for trading suspension history. */
  DATE_REMOVED = 'DATE_REMOVED',
  /** Sort by scheduled date. Only applies to one time cash withdrawals */
  SCHEDULED = 'SCHEDULED',
  /** settlement date only applies to one time cash withdrawals */
  SETTLEMENT_DATE = 'SETTLEMENT_DATE',
  /** Sort by transactionDate for portfolio transactions */
  TRANSACTION_DATE = 'TRANSACTION_DATE'
}

export interface FinancialAccountAssociationInput {
  bankAccount?: InputMaybe<BankAccountInput>;
  bankAccountId?: InputMaybe<Scalars['ID']>;
}

export enum FinancialAccountAssociationStatus {
  COMPLETED = 'COMPLETED',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
  PENDING_RE_SIGN = 'PENDING_RE_SIGN',
  UNKNOWN = 'UNKNOWN'
}

export enum FinancialAccountAssociationVerificationRequestSortField {
  CREATED_ON = 'CREATED_ON'
}

export interface FinancialAccountAssociationVerificationRequestSortInput {
  /** Fields the query can be sorted by */
  field: FinancialAccountAssociationVerificationRequestSortField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum FinancialAccountAssociationVerificationStatus {
  ACCEPTED = 'ACCEPTED',
  IN_REVIEW = 'IN_REVIEW',
  NEEDS_DOCUSIGN = 'NEEDS_DOCUSIGN',
  REJECTED = 'REJECTED',
  UNKNOWN = 'UNKNOWN'
}

/** The source where data is obtained */
export enum FinancialAccountSource {
  DOCUMENT_UPLOAD = 'DOCUMENT_UPLOAD',
  /** A source external to SigFig */
  EXTERNAL = 'EXTERNAL',
  MANUAL = 'MANUAL',
  /** Internal accounts of partner, for internal funding */
  PARTNER_INTERNAL = 'PARTNER_INTERNAL',
  PLAID = 'PLAID',
  /** A source that provides data that has been recently updated.  This data may be several days old */
  SYNCED = 'SYNCED'
}

/** The category of accounts to retrieve from a source */
export enum FinancialAccountSourceType {
  /** Bank accounts are used to fund brokerage accounts */
  BANK = 'BANK',
  /** Brokerage is used to show investment accounts */
  BROKERAGE = 'BROKERAGE'
}

export enum FinancialAccountStatus {
  /** Account was abandoned */
  ABANDONED = 'ABANDONED',
  /** A managed account that can possibly be traded on */
  ACTIVE = 'ACTIVE',
  /** Additional account paperwork documents are required to finish opening an account */
  ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED = 'ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED',
  /** Account has entered end-of-life and is now closed */
  CLOSED = 'CLOSED',
  /** Account paperwork is ready to be signed by the Client */
  LEGAL_DOCUMENTS_PREPARED = 'LEGAL_DOCUMENTS_PREPARED',
  /** Account failed to acquire signatures required for paperwork */
  LEGAL_DOCUMENTS_SIGNATURE_FAILED = 'LEGAL_DOCUMENTS_SIGNATURE_FAILED',
  /** Account paperwork has been signed by the Client */
  LEGAL_DOCUMENTS_SIGNED = 'LEGAL_DOCUMENTS_SIGNED',
  /** The account paperwork documents have been submitted and are awaiting approval */
  LEGAL_DOCUMENTS_SUBMITTED = 'LEGAL_DOCUMENTS_SUBMITTED',
  /** Client has completed MadLibs, RTQ, and funding instructions and selected a product recommendation */
  NEW = 'NEW',
  /** Onboarding process has never been initiated for this account. */
  NOT_STARTED = 'NOT_STARTED',
  /** Client is in the process of getting a product recommendation */
  PARTIAL = 'PARTIAL',
  /** Account has requested to be closed */
  PENDING_CLOSED = 'PENDING_CLOSED',
  UNKNOWN_FINANCIAL_ACCOUNT_STATUS = 'UNKNOWN_FINANCIAL_ACCOUNT_STATUS'
}

export enum FinancialAccountTransactionType {
  ADVISORY_FEE = 'ADVISORY_FEE',
  BOND_OPTION_TRADE = 'BOND_OPTION_TRADE',
  CAPITAL_GAINS = 'CAPITAL_GAINS',
  CAPITAL_GAINS_REINVESTED = 'CAPITAL_GAINS_REINVESTED',
  DIVIDEND_RECEIVED = 'DIVIDEND_RECEIVED',
  DIVIDEND_REINVESTED = 'DIVIDEND_REINVESTED',
  FUNDS_TRANSFER = 'FUNDS_TRANSFER',
  IGNORE = 'IGNORE',
  INTEREST_RECEIVED = 'INTEREST_RECEIVED',
  INTEREST_REINVESTED = 'INTEREST_REINVESTED',
  OTHER = 'OTHER',
  REINVESTMENT = 'REINVESTMENT',
  RETIREMENT_CONTRIBUTION = 'RETIREMENT_CONTRIBUTION',
  SECURITY_NAME_CHANGE = 'SECURITY_NAME_CHANGE',
  TRADE = 'TRADE',
  UNKNOWN = 'UNKNOWN'
}

export enum FinancialAccountType {
  ANNUITY = 'ANNUITY',
  BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN = 'BENEFICIARY_ROTH_IRA_MINOR_CUSTODIAN',
  BENEFICIARY_ROTH_IRA_SPOUSE_NON_SPOUSE = 'BENEFICIARY_ROTH_IRA_SPOUSE_NON_SPOUSE',
  BENEFICIARY_ROTH_IRA_SPOUSE_TRUST = 'BENEFICIARY_ROTH_IRA_SPOUSE_TRUST',
  BENEFICIARY_ROTH_IRA_TRUST = 'BENEFICIARY_ROTH_IRA_TRUST',
  BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN = 'BENEFICIARY_TRADITIONAL_IRA_MINOR_CUSTODIAN',
  BENEFICIARY_TRADITIONAL_IRA_TRUST = 'BENEFICIARY_TRADITIONAL_IRA_TRUST',
  BROKERAGE = 'BROKERAGE',
  BROKERAGE_CASH = 'BROKERAGE_CASH',
  BROKERAGE_MARGIN = 'BROKERAGE_MARGIN',
  BROKERAGE_OPTION = 'BROKERAGE_OPTION',
  BUSINESS = 'BUSINESS',
  CHARITY = 'CHARITY',
  CHEQUING = 'CHEQUING',
  CODA_SEP = 'CODA_SEP',
  CONVERSION = 'CONVERSION',
  CORPORATION = 'CORPORATION',
  COVERDELL_EDUCATION_SAVINGS_PLAN = 'COVERDELL_EDUCATION_SAVINGS_PLAN',
  CUSTODIAL = 'CUSTODIAL',
  CUSTODIAL_ROLLOVER_IRA = 'CUSTODIAL_ROLLOVER_IRA',
  CUSTODIAL_ROTH_IRA = 'CUSTODIAL_ROTH_IRA',
  CUSTODIAL_TRADITIONAL_IRA = 'CUSTODIAL_TRADITIONAL_IRA',
  DEFINED_BENEFIT_PENSION = 'DEFINED_BENEFIT_PENSION',
  EMPLOYEE_STOCK_PURCHASE_PLAN = 'EMPLOYEE_STOCK_PURCHASE_PLAN',
  ESA = 'ESA',
  ESTATE = 'ESTATE',
  FHS = 'FHS',
  FIVE_TWO_NINE = 'FIVE_TWO_NINE',
  FOUR_FIVE_SEVEN_B = 'FOUR_FIVE_SEVEN_B',
  FOUR_ZERO_ONE_A = 'FOUR_ZERO_ONE_A',
  FOUR_ZERO_ONE_K = 'FOUR_ZERO_ONE_K',
  FOUR_ZERO_THREE_B = 'FOUR_ZERO_THREE_B',
  INDIVIDUAL = 'INDIVIDUAL',
  INHERITED_IRA = 'INHERITED_IRA',
  INHERITED_ROTH_IRA = 'INHERITED_ROTH_IRA',
  INHERITED_TRADITIONAL_IRA = 'INHERITED_TRADITIONAL_IRA',
  INVESTMENT = 'INVESTMENT',
  IRA = 'IRA',
  JOINT = 'JOINT',
  JOINT_TENANTS_BY_ENTIRETY = 'JOINT_TENANTS_BY_ENTIRETY',
  JOINT_TENANTS_IN_COMMON = 'JOINT_TENANTS_IN_COMMON',
  KEOGH = 'KEOGH',
  LIE = 'LIE',
  LIR = 'LIR',
  LIS = 'LIS',
  LLC = 'LLC',
  LRI = 'LRI',
  MONEY_MARKET = 'MONEY_MARKET',
  NORMAL = 'NORMAL',
  NRSPR = 'NRSPR',
  OTHER = 'OTHER',
  PARTNERSHIP = 'PARTNERSHIP',
  PRI = 'PRI',
  RDS = 'RDS',
  REF = 'REF',
  REI = 'REI',
  RIS = 'RIS',
  RLI = 'RLI',
  RMA_DOMESTIC = 'RMA_DOMESTIC',
  ROLLOVER_IRA = 'ROLLOVER_IRA',
  ROTH_401K = 'ROTH_401K',
  ROTH_BDA = 'ROTH_BDA',
  ROTH_IRA = 'ROTH_IRA',
  RRI = 'RRI',
  RRS = 'RRS',
  RSL = 'RSL',
  RSS = 'RSS',
  SAVINGS = 'SAVINGS',
  SEP = 'SEP',
  SEP_IRA = 'SEP_IRA',
  SEP_SIMPLE_IRA = 'SEP_SIMPLE_IRA',
  SEP_SIMPLE_ROTH_IRA = 'SEP_SIMPLE_ROTH_IRA',
  SIMPLE = 'SIMPLE',
  SIMPLE_IRA = 'SIMPLE_IRA',
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  TFS = 'TFS',
  TRADITIONAL_IRA = 'TRADITIONAL_IRA',
  TRADITIONAL_IRA_BDA = 'TRADITIONAL_IRA_BDA',
  TRUST = 'TRUST',
  TRUST_UNDER_AGREEMENT = 'TRUST_UNDER_AGREEMENT',
  TRUST_UNDER_WILL = 'TRUST_UNDER_WILL',
  UGMA = 'UGMA',
  UNAVAILABLE = 'UNAVAILABLE',
  UNKNOWN_FINANCIAL_ACCOUNT_TYPE = 'UNKNOWN_FINANCIAL_ACCOUNT_TYPE',
  UTMA = 'UTMA',
  _401K = '_401K'
}

export enum FlagEntity {
  ASSET_DEPOSIT = 'ASSET_DEPOSIT',
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
  CLOSE_WORKFLOW = 'CLOSE_WORKFLOW',
  FUNDING_REVIEW = 'FUNDING_REVIEW',
  RECURRING_CASH_DEPOSIT = 'RECURRING_CASH_DEPOSIT',
  RECURRING_CASH_WITHDRAWAL = 'RECURRING_CASH_WITHDRAWAL'
}

export enum FlagReason {
  API_FAILURE = 'API_FAILURE',
  HIGH_TRANSACTION_VALUE = 'HIGH_TRANSACTION_VALUE',
  OTHER = 'OTHER',
  OVERDUE = 'OVERDUE',
  POSSIBLE_DUPLICATE = 'POSSIBLE_DUPLICATE',
  REJECTED_BANK_ACCOUNT_ASSOCIATION = 'REJECTED_BANK_ACCOUNT_ASSOCIATION'
}

export enum FlagStatus {
  OPEN = 'OPEN',
  RESOLVED = 'RESOLVED'
}

export enum FlowType {
  ONBOARDING = 'ONBOARDING',
  RETAKE = 'RETAKE'
}

export enum FundCategoryTypes {
  CASH_EQUIVALENT = 'CASH_EQUIVALENT',
  CORPORATE_CLASS = 'CORPORATE_CLASS',
  UNKNOWN = 'UNKNOWN'
}

/** Fund Input */
export interface FundInput {
  /** The security_id of the fund to fetch a live rate for. Sigfig recognized id that may or may not be recognized by the partner. */
  id: Scalars['String'];
  /** Provides period & unit of measure to retrieve rate information */
  term: RateTermInput;
  /** The partner recognized security_id of the fund to fetch the live rate for. */
  underlyingId?: InputMaybe<Scalars['String']>;
}

export enum FundType {
  ANNUITY = 'ANNUITY',
  ASSET = 'ASSET',
  CASH = 'CASH'
}

export enum FundingReviewSortField {
  /** Sort by ManagedProduct.CreateWorkflow.activatedAt */
  ACTIVATED_AT = 'ACTIVATED_AT'
}

export enum FundingReviewStatus {
  BELOW_MINIMUM = 'BELOW_MINIMUM',
  FUNDED = 'FUNDED',
  NEEDS_REVIEW = 'NEEDS_REVIEW'
}

export interface FundingReviewsSortInput {
  /** Fields that support sorting */
  field: FundingReviewSortField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum FundingSourceType {
  ANNUITY = 'ANNUITY',
  BANK_ACCOUNT_CHECKING = 'BANK_ACCOUNT_CHECKING',
  BANK_ACCOUNT_SAVINGS = 'BANK_ACCOUNT_SAVINGS',
  BROKERAGE_ACCOUNT = 'BROKERAGE_ACCOUNT',
  CASH = 'CASH',
  CHECK = 'CHECK',
  EMPLOYER_SPONSORED_PLAN = 'EMPLOYER_SPONSORED_PLAN',
  EXCHANGE = 'EXCHANGE',
  OTHER_BROKERAGE_ACCOUNT = 'OTHER_BROKERAGE_ACCOUNT',
  ROLLOVER_401K = 'ROLLOVER_401K',
  SAME_BROKERAGE_ACCOUNT = 'SAME_BROKERAGE_ACCOUNT',
  UNKNOWN = 'UNKNOWN'
}

export enum Gender {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}

export enum GlidePath {
  FLAT = 'FLAT',
  RETIREMENT = 'RETIREMENT'
}

/** The letter grade performance for guidance */
export enum GuidanceGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
  UNKNOWN_GRADE = 'UNKNOWN_GRADE'
}

export enum GuidanceProductIneligibilityReason {
  EMPTY = 'EMPTY',
  HIDDEN = 'HIDDEN',
  INCOMPLETE_MANUAL = 'INCOMPLETE_MANUAL',
  MANAGED = 'MANAGED'
}

export enum GuidanceProductStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
  INELIGIBLE = 'INELIGIBLE'
}

export interface HamiltonClientInput {
  /** Age of the client */
  age: Scalars['Int'];
  /** Client Name */
  name: Scalars['String'];
}

export interface HamiltonLifeEventEmailInput {
  /** Client details */
  client: HamiltonClientInput;
  /** Life event list object */
  lifeEvent: HamiltonLifeEventInput;
}

export interface HamiltonLifeEventInput {
  /** List of life events */
  event: Array<InputMaybe<Scalars['String']>>;
}

export enum IdentifierType {
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  GOVERNMENT = 'GOVERNMENT',
  OTHER = 'OTHER',
  PASSPORT = 'PASSPORT',
  SSN = 'SSN',
  STATE_LOCAL = 'STATE_LOCAL',
  TIN = 'TIN',
  UNKNOWN = 'UNKNOWN',
  VISA = 'VISA'
}

export enum InterestFrequency {
  LONG_TERM = 'LONG_TERM',
  SHORT_TERM = 'SHORT_TERM'
}

export interface InvestmentAmountsInput {
  nonQualified?: InputMaybe<Scalars['String']>;
  percentage?: InputMaybe<Scalars['Int']>;
  qualified?: InputMaybe<Scalars['String']>;
}

export interface InvestmentExperienceDetailInput {
  alternativeInvestments: InvestmentAmountsInput;
  annuitiesAndLifeInsurance: InvestmentAmountsInput;
  bonds: InvestmentAmountsInput;
  foreignCurrency: InvestmentAmountsInput;
  foreignSecurity: InvestmentAmountsInput;
  lifeInsurance: InvestmentAmountsInput;
  limitedPartnerships: InvestmentAmountsInput;
  mutualFunds: InvestmentAmountsInput;
  options: InvestmentAmountsInput;
  other: InvestmentAmountsInput;
  otherAssetClass?: InputMaybe<Scalars['String']>;
  securityFutures: InvestmentAmountsInput;
  shortTerms: InvestmentAmountsInput;
  stocks: InvestmentAmountsInput;
  total: InvestmentAmountsInput;
  variableContracts: InvestmentAmountsInput;
}

export interface InvestmentInput {
  accountPurpose?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingAnnuitiesAndLifeInsurance?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingBonds?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingMutualFunds?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingOptions?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingOther?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingStocks?: InputMaybe<Scalars['String']>;
  experienceBuyingOrSellingVariableContracts?: InputMaybe<Scalars['String']>;
  experienceDetail?: InputMaybe<InvestmentExperienceDetailInput>;
  hasExperienceBuyingOrSellingInstruments?: InputMaybe<Scalars['Boolean']>;
  investmentExperience?: InputMaybe<Scalars['String']>;
  investmentHorizon?: InputMaybe<Scalars['String']>;
  investmentObjective?: InputMaybe<Scalars['String']>;
}

export interface InvestmentProductSwitchAssetInput {
  /** Amount being redeemded */
  amount?: InputMaybe<Scalars['Decimal']>;
  /** Current annuity death benefit base */
  annuityDeathBenefitBase?: InputMaybe<Scalars['Decimal']>;
  /** Current annuity income benefit base */
  annuityIncomeBenefitBase?: InputMaybe<Scalars['Decimal']>;
  /** Annuity Minimum guaranteed rate */
  annuityMinimumGuaranteedRate?: InputMaybe<Scalars['Decimal']>;
  /** Annuity riders annual cost */
  annuityRidersAnnualCost?: InputMaybe<Scalars['Decimal']>;
  /** Estimated Investment held for months */
  investmentHeldForMonths?: InputMaybe<Scalars['Int']>;
  /** Estimated Investment held for years */
  investmentHeldForYears?: InputMaybe<Scalars['Int']>;
  /** Name of product */
  name?: InputMaybe<Scalars['String']>;
  /** Sales/Surrender Charges paid */
  salesCharge?: InputMaybe<Scalars['Decimal']>;
  /** Current surrender charges paid */
  surrenderCharges?: InputMaybe<Scalars['Decimal']>;
}

export enum InvestmentPurposeTypes {
  OTHER = 'OTHER',
  RETIREMENT = 'RETIREMENT'
}

/** A list of supported languages */
export enum LanguageType {
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH'
}

export enum LegalDocumentStatus {
  DECLINED = 'DECLINED',
  ENVELOPE_PREPARATION = 'ENVELOPE_PREPARATION',
  ENVELOPE_PREPARATION_FAILED = 'ENVELOPE_PREPARATION_FAILED',
  FAILED = 'FAILED',
  PENDING = 'PENDING',
  PENDING_PRIMARY = 'PENDING_PRIMARY',
  PENDING_SECONDARY = 'PENDING_SECONDARY',
  SUCCEEDED = 'SUCCEEDED',
  VOIDED = 'VOIDED'
}

export interface LiabilitiesInput {
  autoLoans?: InputMaybe<Scalars['Decimal']>;
  creditCardDebt?: InputMaybe<Scalars['Decimal']>;
  mortgage?: InputMaybe<Scalars['Decimal']>;
  other?: InputMaybe<Scalars['Decimal']>;
  personalLoans?: InputMaybe<Scalars['Decimal']>;
  studentLoans?: InputMaybe<Scalars['Decimal']>;
}

export interface LiveRateInput {
  /** Certificate number */
  certificateNumber?: InputMaybe<Scalars['String']>;
  /** Flag to indicate customer initiated or not */
  customerInitiated: Scalars['Boolean'];
  /** Type of the GIC */
  gicType: Scalars['String'];
  interestBasis: Scalars['Int'];
  /** Interest Frequency for GIC */
  interestFrequency: InterestFrequency;
  /** Is compound rate */
  isCompoundRate?: InputMaybe<Scalars['String']>;
  /** The issuer of the live rate. */
  issuer: Scalars['String'];
  /** Maturity Date of GIC */
  maturityDate: Scalars['DateTime'];
  /** Pricing request identifier */
  pricingRequestId: Scalars['Int'];
  /** Rate Information for GICs */
  rates: LiveRateRatesInput;
  /** The Id of the security */
  securityId: Scalars['String'];
  /** Martket Linked GICs strike date */
  strikeDate?: InputMaybe<Scalars['DateTime']>;
  /** The terms describing the temporal validity of the rate */
  term: LiveRateTermsInput;
}

export interface LiveRateRatesInput {
  /** Market Linked GICs Interim Rate */
  interimRate?: InputMaybe<Scalars['Decimal']>;
  /** Market Linked GICs Maximum Rate */
  maximumRate?: InputMaybe<Scalars['Decimal']>;
  /** Target rate for GIC */
  targetRate: Scalars['Decimal'];
}

export interface LiveRateTermsInput {
  /** Period of term range ie: 12 for display */
  displayPeriod: Scalars['Int'];
  /** Unit of measure for term range: ie: Month for display */
  displayUnit: RateUnitType;
  /** End of range */
  end?: InputMaybe<Scalars['Int']>;
  /** Start of range */
  start?: InputMaybe<Scalars['Int']>;
  /** Unit of measure for term range: ie: Month */
  unit?: InputMaybe<RateUnitType>;
}

/** Managed Product Additional Attributes */
export interface ManagedProductAdditionalAttributesInput {
  /** Age of termination for UGMA/UTMA accounts */
  ageOfTermination?: InputMaybe<Scalars['Int']>;
  /** Account identifier used for asset consolidation */
  assetConsolidationAccountIdentifier?: InputMaybe<Scalars['String']>;
  /** ID of Managed Product to clone */
  clonedManagedProductId?: InputMaybe<Scalars['String']>;
  /** Identifies if manged product is created via content module */
  initiatedViaContentModule?: InputMaybe<Scalars['Boolean']>;
  /** File path for uploaded documents on AWS S3 */
  uploadedFilePath?: InputMaybe<Scalars['String']>;
}

export interface ManagedProductEntity {
  entityAttributes: ManagedProductAdditionalAttributesInput;
  entityId: Scalars['ID'];
}

export enum ManagedProductLegalDocumentType {
  ASSET_TRANSFER_ADDENDUM = 'ASSET_TRANSFER_ADDENDUM',
  ASSET_TRANSFER_AUTHORIZATION = 'ASSET_TRANSFER_AUTHORIZATION',
  BANK_SWEEP_DISCLOSURE = 'BANK_SWEEP_DISCLOSURE',
  DIRECT_ROLLOVER_AUTHORIZATION = 'DIRECT_ROLLOVER_AUTHORIZATION',
  E_DELIVERY_ACCEPTANCE = 'E_DELIVERY_ACCEPTANCE',
  FIDUCIARY_ACKNOWLEDGEMENT = 'FIDUCIARY_ACKNOWLEDGEMENT',
  FORM_ADV = 'FORM_ADV',
  INVESTMENT_ADVISORY_AGREEMENT = 'INVESTMENT_ADVISORY_AGREEMENT',
  INVESTMENT_MANAGEMENT_AGREEMENT = 'INVESTMENT_MANAGEMENT_AGREEMENT',
  IRA_APPLICATION = 'IRA_APPLICATION',
  MANAGED_AUTHORIZATION = 'MANAGED_AUTHORIZATION',
  MOVE_MONEY = 'MOVE_MONEY',
  NEW_ACCOUNT = 'NEW_ACCOUNT',
  OTHER = 'OTHER',
  PARTIAL_TRANSFER = 'PARTIAL_TRANSFER',
  PRIVACY_DISCLOSURE = 'PRIVACY_DISCLOSURE',
  RELATIONSHIP_SUMMARY = 'RELATIONSHIP_SUMMARY',
  ROTH_IRA_APPLICATION = 'ROTH_IRA_APPLICATION',
  TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
  TRUSTEE_CERTIFICATION_OF_INVESTMENT_POWERS = 'TRUSTEE_CERTIFICATION_OF_INVESTMENT_POWERS',
  UNKNOWN = 'UNKNOWN'
}

export enum ManagedProductStatus {
  /** Account was abandoned */
  ABANDONED = 'ABANDONED',
  /** A managed account that can possibly be traded on */
  ACTIVE = 'ACTIVE',
  /** Additional account paperwork documents are required to finish opening an account */
  ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED = 'ADDITIONAL_LEGAL_DOCUMENTS_REQUIRED',
  /** Account has entered end-of-life and is now closed */
  CLOSED = 'CLOSED',
  /** Account paperwork is ready to be signed by the Client */
  LEGAL_DOCUMENTS_PREPARED = 'LEGAL_DOCUMENTS_PREPARED',
  /** Account failed to acquire signatures required for paperwork */
  LEGAL_DOCUMENTS_SIGNATURE_FAILED = 'LEGAL_DOCUMENTS_SIGNATURE_FAILED',
  /** Account paperwork has been signed by the Client */
  LEGAL_DOCUMENTS_SIGNED = 'LEGAL_DOCUMENTS_SIGNED',
  /** The account paperwork documents have been submitted and are awaiting approval */
  LEGAL_DOCUMENTS_SUBMITTED = 'LEGAL_DOCUMENTS_SUBMITTED',
  /** Client has completed MadLibs, RTQ, and funding instructions and selected a product recommendation */
  NEW = 'NEW',
  /** Onboarding process has never been initiated for this account. */
  NOT_STARTED = 'NOT_STARTED',
  /** Client is in the process of getting a product recommendation */
  PARTIAL = 'PARTIAL',
  /** Account has requested to be closed */
  PENDING_CLOSED = 'PENDING_CLOSED',
  /** A managed account status that is unknown */
  UNKNOWN_FINANCIAL_ACCOUNT_STATUS = 'UNKNOWN_FINANCIAL_ACCOUNT_STATUS'
}

export enum ManagedProductType {
  DIGITAL_ADVICE = 'DIGITAL_ADVICE',
  DIGITAL_ADVICE_PRO = 'DIGITAL_ADVICE_PRO'
}

export enum ManagedProductsField {
  CREATED = 'CREATED'
}

export interface ManagedProductsSortInput {
  /** Fields that support sorting */
  field: ManagedProductsField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum MaritalStatus {
  DIVORCED = 'DIVORCED',
  DOMESTIC_PARTNER = 'DOMESTIC_PARTNER',
  MARRIED = 'MARRIED',
  SEPARATED = 'SEPARATED',
  SINGLE = 'SINGLE',
  UNKNOWN = 'UNKNOWN',
  WIDOWED = 'WIDOWED'
}

export interface MetricValueFilterInput {
  max: Scalars['Decimal'];
  metricId: Scalars['String'];
  min: Scalars['Decimal'];
}

export interface ModelPortfolioAllocationInput {
  /** Model portfolio internal identifier */
  modelPortfolioInternalId: Scalars['Int'];
  /** Model portfolio series id */
  modelPortfolioSeriesId: Scalars['Int'];
  /** Percentage in composite (if multiple are provided) */
  percentage?: InputMaybe<Scalars['Decimal']>;
}

export interface ModelPortfolioInput {
  allocationPercentage: Scalars['Decimal'];
  modelPortfolioInternalId: Scalars['Int'];
  seriesId: Scalars['Int'];
}

export interface ModelPortfoliosFilterInput {
  /** Filters based around product details. */
  productFilter?: InputMaybe<ProductFilterInput>;
  /** Filters based around RTQ results. */
  recommendationFilter?: InputMaybe<RecommendationFilterInput>;
  /** Filter to fetch Modern Portfolio Theory Return Factors. */
  returnFactorsFilter?: InputMaybe<ReturnFactorsFilterInput>;
  /**
   *   List of selected model portfolios to fetch full model portfolio for.
   *   CAN NOT be used in combination with: eligible_product_variants | recommended_product_type | scenario_id
   *   MUST be used in combination with: product_filter.role.
   *     (Requires VisibilityRoleFilter for downstream BAM call which results in searching all model portfolios.
   *     Assume that this arg is for validating a model portfolio and we want to check everything when used.)
   *
   */
  selectedModelPortfolios?: InputMaybe<Array<SelectedModelPortfolioFilterInput>>;
}

export enum ModelPortfoliosSortType {
  NON_RETIREMENT = 'NON_RETIREMENT',
  RETIREMENT = 'RETIREMENT'
}

export interface MoneyInput {
  /** The type of currency defined by ISO 4217 */
  currencyCode: Scalars['String'];
  /** The amount of the currency */
  value: Scalars['Decimal'];
}

export interface MorningstarProposalInput {
  currentValue: Scalars['Decimal'];
  employerPlanSponsor?: InputMaybe<Scalars['String']>;
  estimatedFundingAmount: Scalars['Decimal'];
  fundingAccountType: FinancialAccountType;
  heldForAtLeastTwoYears?: InputMaybe<Scalars['Boolean']>;
  inServiceRollover?: InputMaybe<Scalars['Boolean']>;
  isInternalAccount?: InputMaybe<Scalars['Boolean']>;
  morningstarProposalId?: InputMaybe<Scalars['String']>;
  outstandingLoanAmount?: InputMaybe<Scalars['Decimal']>;
  outstandingLoans?: InputMaybe<Scalars['Boolean']>;
  planNumber?: InputMaybe<Scalars['String']>;
  proposalCreated?: InputMaybe<Scalars['String']>;
  proposalName?: InputMaybe<Scalars['String']>;
  proposalTypeId?: InputMaybe<Scalars['String']>;
  receivedFourZeroFourA5?: InputMaybe<Scalars['Boolean']>;
  rolloverType: MorningstarRolloverType;
  surrenderType: SurrenderType;
}

export enum MorningstarProposalVerificationResult {
  AUTO_PASS = 'AUTO_PASS',
  PENDING = 'PENDING'
}

export enum MorningstarRolloverType {
  ACCOUNT_TRANSFER = 'ACCOUNT_TRANSFER',
  DIRECT = 'DIRECT',
  SIXTY_DAY_INDIRECT = 'SIXTY_DAY_INDIRECT'
}

export enum MultiCashTransferType {
  /** One time cash deposit transfer type */
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  /** One time cash withdrawal transfer type */
  CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
  /** Recurring cash deposit transfer type */
  RECURRING_CASH_DEPOSIT = 'RECURRING_CASH_DEPOSIT',
  /** Recurring cash withdrawal transfer type */
  RECURRING_CASH_WITHDRAWAL = 'RECURRING_CASH_WITHDRAWAL'
}

export enum MultiRestrictionEntityType {
  RESTRICTION = 'RESTRICTION',
  TRADING_SUSPENSION = 'TRADING_SUSPENSION'
}

export enum MultiRestrictionPlacedBy {
  CUSTODIAL = 'CUSTODIAL',
  PARTNER_ACAT = 'PARTNER_ACAT',
  PARTNER_BORD = 'PARTNER_BORD',
  PARTNER_OPS = 'PARTNER_OPS',
  PARTNER_WITHOUT_TAG = 'PARTNER_WITHOUT_TAG',
  SIGFIG_TRADER = 'SIGFIG_TRADER'
}

export enum MultiRestrictionType {
  BILLING = 'BILLING',
  CLOSING_TRADES_ONLY = 'CLOSING_TRADES_ONLY',
  EDUCATIONAL_ASSETS_ONLY = 'EDUCATIONAL_ASSETS_ONLY',
  LIQUIDATING_TRADES_ONLY = 'LIQUIDATING_TRADES_ONLY',
  MONEY_IN = 'MONEY_IN',
  MONEY_OUT = 'MONEY_OUT',
  REGULATION_T = 'REGULATION_T',
  TRADING = 'TRADING',
  UNKNOWN_RESTRICTION = 'UNKNOWN_RESTRICTION'
}

export interface NextStepsInput {
  nextSteps?: InputMaybe<Array<Scalars['String']>>;
}

export interface OffsetInput {
  name: Scalars['String'];
  value: Scalars['Int'];
}

export enum OnboardingStates {
  ASSET_CONSOLIDATION = 'ASSET_CONSOLIDATION',
  FUNDING = 'FUNDING',
  GOALS = 'GOALS',
  INVESTMENT_SWITCH = 'INVESTMENT_SWITCH',
  MADLIBS = 'MADLIBS',
  PAPERWORK = 'PAPERWORK',
  PLAN = 'PLAN',
  PLAN_RECOMMENDATION = 'PLAN_RECOMMENDATION',
  PLAYBACK = 'PLAYBACK',
  PORTFOLIO_ANALYSIS = 'PORTFOLIO_ANALYSIS',
  PORTFOLIO_SELECTION = 'PORTFOLIO_SELECTION',
  RTQ = 'RTQ',
  RTQ_RESULT = 'RTQ_RESULT',
  SUITABILITY = 'SUITABILITY',
  SUITABILITY_RESULT = 'SUITABILITY_RESULT',
  UNKNOWN = 'UNKNOWN'
}

export enum OneTimePasswordRole {
  PLAID_CUSTOMER = 'PLAID_CUSTOMER'
}

export interface OptionsInput {
  /** partyId of the FA. */
  faPartyId?: InputMaybe<Scalars['ID']>;
}

export enum OrderSubset {
  A_C_SHARE = 'A_C_SHARE',
  A_SHARE = 'A_SHARE',
  C_SHARE = 'C_SHARE',
  MADLIBS = 'MADLIBS',
  PORTFOLIO_COMPARE_RTQ = 'PORTFOLIO_COMPARE_RTQ',
  RETAKE_RTQ = 'RETAKE_RTQ',
  RTQ = 'RTQ',
  UNKNOWN = 'UNKNOWN'
}

export enum OrderType {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

/** How many records to skip and the total records to return */
export interface PaginationInput {
  /** The total number of results to return. */
  limit: Scalars['Int'];
  /** The number of results to skip. */
  offset: Scalars['Int'];
}

export interface PaperworkAdditionalAttributesInput {
  addGoldmanSachSlcSblDisclosure?: InputMaybe<Scalars['String']>;
  coApplicantRelationshipType?: InputMaybe<Scalars['String']>;
  costBasisDisposalMethod?: InputMaybe<Scalars['String']>;
  dividendDistributionIncome?: InputMaybe<Scalars['String']>;
  eDeliveryEmailAddress?: InputMaybe<Scalars['String']>;
  enrollInEDelivery?: InputMaybe<Scalars['String']>;
  entityProfileType?: InputMaybe<EntityProfileType>;
  formCrsDeliveryDate?: InputMaybe<Scalars['Date']>;
  formCrsDeliveryMethod?: InputMaybe<Scalars['String']>;
  isFormCrsReviewed?: InputMaybe<Scalars['Boolean']>;
  otherIssuingAgency?: InputMaybe<Scalars['String']>;
  ownershipPercentage?: InputMaybe<Scalars['Decimal']>;
  pledgedAccountNumbers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  proceeds?: InputMaybe<Scalars['String']>;
  receiveAssetMovementAuthorizationForm?: InputMaybe<Scalars['String']>;
  receiveConfirmsViaEmail?: InputMaybe<Scalars['Boolean']>;
  receiveCustomerCorrespondenceViaEmail?: InputMaybe<Scalars['Boolean']>;
  receiveShareholderDocumentsViaEmail?: InputMaybe<Scalars['Boolean']>;
  receiveStatementsViaEmail?: InputMaybe<Scalars['Boolean']>;
  receiveTaxDocumentsViaEmail?: InputMaybe<Scalars['Boolean']>;
  referralSource?: InputMaybe<Scalars['String']>;
  repCode?: InputMaybe<Scalars['String']>;
  securityPurchases?: InputMaybe<Scalars['String']>;
  selectContact?: InputMaybe<Scalars['String']>;
  selectedContactPartyId?: InputMaybe<Scalars['ID']>;
  tenantsInCommonPercentage?: InputMaybe<Scalars['String']>;
}

export interface PaperworkInput {
  additionalAttributes?: InputMaybe<PaperworkAdditionalAttributesInput>;
  /** Identifier for the given entity */
  id?: InputMaybe<Scalars['String']>;
  investment?: InputMaybe<InvestmentInput>;
  /** Flag to indicate is home address is same as of primary paperwork in case of joint account */
  isHomeAddressDerivedFromPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Flag to indicate is mailing address is same as of primary paperwork in case of joint account */
  isMailingAddressDerivedFromPrimary?: InputMaybe<Scalars['Boolean']>;
  /** Flag to indicate is mailing address same as home address for this paperwork */
  isMailingAddressSameAsHomeAddress?: InputMaybe<Scalars['Boolean']>;
  isProxyVotingEnabled?: InputMaybe<Scalars['Boolean']>;
  /** The id of the managed account this paperwork belongs to */
  managedId: Scalars['String'];
  /** UI driven identifier for differentiating between sets of paperwork */
  paperworkFreeFormId?: InputMaybe<Scalars['String']>;
  /** The type indicates for which user the paperwork belongs to in case of joint accounts */
  paperworkType: PaperworkType;
  party: PartyInput;
  regulatoryInformation?: InputMaybe<RegulatoryInformationInput>;
  relationships?: InputMaybe<Array<RelationshipInput>>;
  tradeConfirmationFrequency?: InputMaybe<Scalars['String']>;
  trustInformation?: InputMaybe<TrustInformationInput>;
  wealthInformation?: InputMaybe<WealthInput>;
}

export enum PaperworkType {
  PRIMARY = 'PRIMARY',
  SECONDARY = 'SECONDARY',
  UNKNOWN = 'UNKNOWN'
}

export enum PartnerProductEnum {
  GIC = 'GIC',
  HISA = 'HISA',
  MUTUAL_FUND = 'MUTUAL_FUND',
  UNKNOWN = 'UNKNOWN'
}

export interface PartyAttributeInput {
  assetInformation?: InputMaybe<AssetInformationInput>;
  employmentInformation?: InputMaybe<EmploymentInformationInput>;
  familyInformation?: InputMaybe<FamilyInformationInput>;
  nextSteps?: InputMaybe<NextStepsInput>;
  preferences?: InputMaybe<PreferencesInput>;
  priorities?: InputMaybe<PrioritiesInput>;
}

export interface PartyContactInput {
  /** Contact information */
  contact: Scalars['String'];
  isPrimary?: InputMaybe<Scalars['Boolean']>;
  /** The ID of this entity used to uniquely identify from other contacts */
  partyContactId?: InputMaybe<Scalars['ID']>;
  /** Contact type */
  type: ContactType;
}

export interface PartyIdentifierInput {
  /** The country where the id was issued */
  identifierCountry?: InputMaybe<Scalars['String']>;
  identifierExpiry?: InputMaybe<Scalars['Date']>;
  /** The date the identifier was issued */
  identifierIssuance?: InputMaybe<Scalars['Date']>;
  /** The state where the id was issued */
  identifierJurisdiction?: InputMaybe<Scalars['String']>;
  identifierValue?: InputMaybe<Scalars['String']>;
  /** This flag is use to identify if the SSN or TIN value should be set to null in BAM, wherever applicable. */
  setValueToNull?: InputMaybe<Scalars['Boolean']>;
  /** The type of document which identifies user's identity */
  type: IdentifierType;
}

export interface PartyInput {
  /** All the addresses of the party */
  addresses?: InputMaybe<Array<AddressInput>>;
  /** Party's additional attribute */
  attribute?: InputMaybe<PartyAttributeInput>;
  /** The ID of this entity used to uniquely identify from other parties */
  id?: InputMaybe<Scalars['ID']>;
  /** Government issued id to the party */
  identifiers?: InputMaybe<Array<PartyIdentifierInput>>;
  /** Party details when party is a business entity */
  partyBusinessEntity?: InputMaybe<BusinessEntityInput>;
  /** Party's organization information */
  partyCompany?: InputMaybe<CompanyInput>;
  /** Party contacts */
  partyContacts?: InputMaybe<Array<PartyContactInput>>;
  /** The person's profile data */
  partyPerson?: InputMaybe<PartyPersonInput>;
  partyType?: InputMaybe<PartyType>;
  /** Party's wealth information */
  partyWealthInformation?: InputMaybe<WealthInput>;
  /** Party relationships */
  relationships?: InputMaybe<Array<RelationshipInput>>;
}

export interface PartyPersonInput {
  birthDate?: InputMaybe<Scalars['Date']>;
  citizenship?: InputMaybe<Scalars['String']>;
  deathDate?: InputMaybe<Scalars['Date']>;
  employmentStatus?: InputMaybe<EmploymentStatus>;
  /** The last name of a person */
  familyName?: InputMaybe<Scalars['String']>;
  gender?: InputMaybe<Gender>;
  /** The first name of a person */
  givenName?: InputMaybe<Scalars['String']>;
  /** Language Input(s) for Person */
  language?: InputMaybe<PartyPersonLanguageInput>;
  maritalStatus?: InputMaybe<MaritalStatus>;
  middleName?: InputMaybe<Scalars['String']>;
  occupation?: InputMaybe<Scalars['String']>;
  residentType?: InputMaybe<Scalars['Int']>;
  taxStatus?: InputMaybe<TaxStatus>;
}

export interface PartyPersonLanguageInput {
  /** Is English all account holder's primary language */
  isEnglishPrimaryLanguage?: InputMaybe<Scalars['String']>;
  /** Account holder's primary language if English is not selected as primary and other is selected in primary_language option. */
  otherPrimaryLanguage?: InputMaybe<Scalars['String']>;
  /** Account holder's primary language if English is not selected as primary. */
  primaryLanguage?: InputMaybe<Scalars['String']>;
}

export enum PartyType {
  BUSINESS_ENTITY = 'BUSINESS_ENTITY',
  COMPANY = 'COMPANY',
  FINANCIAL_INSTITUTION = 'FINANCIAL_INSTITUTION',
  PERSON = 'PERSON',
  UNKNOWN_PARTY = 'UNKNOWN_PARTY'
}

export enum PendingRmdStatus {
  ACTION_REQUIRED = 'ACTION_REQUIRED',
  NO_PENDING_RMD = 'NO_PENDING_RMD',
  REVIEW_REQUIRED = 'REVIEW_REQUIRED'
}

/** The parameters used to calculate account performance */
export interface PerformanceMeasurementInput {
  /** The start date for the performance calcuations */
  from: Scalars['Date'];
  /** A name used to describe the performance (i.e QTD, YTD) */
  name?: InputMaybe<Scalars['String']>;
  /** How to calculate requested data, may override portfolio settings */
  performanceMethod?: InputMaybe<PerformanceMethodTypes>;
  /** The end date that the performance calculation stops */
  to: Scalars['Date'];
}

export enum PerformanceMethodTypes {
  FROM_ACTIVITY = 'FROM_ACTIVITY',
  FROM_END_OF_DAY_VALUES = 'FROM_END_OF_DAY_VALUES'
}

export enum PeriodType {
  FIVE_YEAR = 'FIVE_YEAR',
  ONE_YEAR = 'ONE_YEAR',
  SINCE_INCEPTION = 'SINCE_INCEPTION',
  TEN_YEAR = 'TEN_YEAR',
  THREE_YEAR = 'THREE_YEAR'
}

export enum PersonType {
  CLIENT = 'CLIENT',
  DEFAULT = 'DEFAULT',
  EMPLOYEE = 'EMPLOYEE'
}

export interface PlaidAccountMetadata {
  /** Account id generated with public token */
  id: Scalars['String'];
  /** Account name mask generated with public token */
  mask?: InputMaybe<Scalars['String']>;
  /** Account name generated with public token */
  name: Scalars['String'];
  /** Account type generated with public token */
  type: Scalars['String'];
}

export enum PlaidEventAction {
  LOGIN = 'LOGIN',
  RE_AUTHENTICATION = 'RE_AUTHENTICATION'
}

export interface PlaidInstitutionMetadata {
  /** Institution identifier generated with public token */
  institutionId: Scalars['String'];
  /** Institution name generated with public token */
  name?: InputMaybe<Scalars['String']>;
}

export interface PlaidMetadata {
  /** Accounts details generated with public token */
  accounts: Array<PlaidAccountMetadata>;
  /** Institution details generated with public token */
  institution: PlaidInstitutionMetadata;
}

export enum PlaidProduct {
  AUTH = 'AUTH',
  INVESTMENTS = 'INVESTMENTS'
}

export enum PlanOption {
  VIA_CALL = 'VIA_CALL',
  VIA_FORM = 'VIA_FORM'
}

export enum PlanUpdateWorkflowField {
  CREATED = 'CREATED'
}

export interface PlanUpdateWorkflowSortInput {
  /** Fields that support sorting */
  field: PlanUpdateWorkflowField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum PlanUpdateWorkflowStatus {
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  DOCS_PREPARED = 'DOCS_PREPARED',
  DOCS_SIGNED = 'DOCS_SIGNED',
  PENDING = 'PENDING',
  SIGNATURE_FAILED = 'SIGNATURE_FAILED'
}

export enum PortfolioGrade {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  F = 'F',
  UNKNOWN = 'UNKNOWN'
}

export interface PositionInput {
  /** Attributes for positions */
  attributes?: InputMaybe<PositionsAttributesInput>;
  fromTypeCode?: InputMaybe<Scalars['String']>;
  /** Identifiers used to uniquely define this position (i.e Symbol: APPL)  */
  identifiers: Array<StringAttributesInput>;
  /** The amount of units of specified identifiers */
  quantities: Array<QuantityInput>;
  toTypeCode?: InputMaybe<Scalars['String']>;
  transferAllUnits?: InputMaybe<Scalars['Boolean']>;
}

export interface PositionTransferInput {
  estimatedValue?: InputMaybe<Scalars['Decimal']>;
  positions?: InputMaybe<Array<PositionInput>>;
}

export interface PositionsAttributesInput {
  brokerOrDealer?: InputMaybe<Scalars['String']>;
  estimatedNetRedemptionValue?: InputMaybe<Scalars['Decimal']>;
  estimatedSalesChargeAmount?: InputMaybe<Scalars['Decimal']>;
  expenseRatio?: InputMaybe<Scalars['Decimal']>;
  isManualSecurity?: InputMaybe<Scalars['Boolean']>;
  mostRecentPurchaseDate?: InputMaybe<Scalars['Date']>;
  portfolioAssetClassBlend?: InputMaybe<Scalars['String']>;
  shareClass?: InputMaybe<Scalars['String']>;
}

export interface PreferencesInput {
  discussion: Scalars['String'];
  priorities?: InputMaybe<Array<Scalars['String']>>;
}

export interface PrioritiesInput {
  priorities?: InputMaybe<Array<Scalars['String']>>;
}

export enum ProcessingStatusTypes {
  PROCESSING_ANALYZING = 'PROCESSING_ANALYZING',
  PROCESSING_CONNECTING = 'PROCESSING_CONNECTING',
  PROCESSING_DOWNLOADING = 'PROCESSING_DOWNLOADING',
  PROCESSING_FINDING = 'PROCESSING_FINDING',
  PROCESSING_NEW = 'PROCESSING_NEW',
  PROCESSING_QUEUED = 'PROCESSING_QUEUED',
  SUCCESS = 'SUCCESS'
}

export enum ProductContext {
  /** All products that exist for the client */
  ACTIVE = 'ACTIVE',
  /** All eligible products for the client */
  ELIGIBLE = 'ELIGIBLE'
}

export interface ProductFilterInput {
  /** Filter by brokerage alias. */
  brokerageAlias?: InputMaybe<Scalars['String']>;
  /** Fetch only tax sheltered portfolios. */
  isTaxSheltered?: InputMaybe<Scalars['Boolean']>;
  /**
   * Filter for the Product Type of the recommended portfolio.
   * CAN NOT be used in combination with: selected_model_portfolios | series_ids
   *
   */
  recommendedProductType?: InputMaybe<Array<PartnerProductEnum>>;
  /** The role of the consumer we are fetching recommended portfolios for. Do not include when fetching primary recommendations. */
  role?: InputMaybe<ConsumerRoleType>;
}

export enum ProductType {
  GUIDANCE = 'GUIDANCE',
  MANAGED = 'MANAGED'
}

export interface QuantityInput {
  /** The monetary value of one unit */
  marketValue?: InputMaybe<MoneyInput>;
  type: QuantityType;
  /** The number of units */
  units: Scalars['Decimal'];
}

export enum QuantityType {
  AVAILABLE = 'AVAILABLE',
  LONG = 'LONG',
  SHORT = 'SHORT',
  TOTAL = 'TOTAL',
  UNKNOWN_QUANTITY = 'UNKNOWN_QUANTITY'
}

export interface QuestionStepInput {
  dataPoints: Array<DataPointInput>;
  /** The key related to the question */
  questionKey: Scalars['ID'];
  /** The displayed text for the question. */
  questionText: Scalars['String'];
}

export interface QuestionnaireEntityInput {
  entityId: Scalars['ID'];
  entityType: QuestionnaireEntityType;
}

export enum QuestionnaireEntityType {
  ASSET_FUNDING_OPTION = 'ASSET_FUNDING_OPTION',
  MANAGED_PRODUCT = 'MANAGED_PRODUCT'
}

export enum QuestionnaireFilterTypes {
  /**
   * LATEST was added to support migrated accounts. There is an edge case where an active migrated account has a PUW in a PENDING state.
   * This will grab the questionnaire and recommendations for a PUW in either PENDING or COMPLETED state.
   *
   */
  LATEST = 'LATEST',
  /**
   * ONBOARDING_COMPLETED will get the results from the most recent RTQ completion for this managedProduct & plan.
   * This applies to the case where onboarding was completed and plan was accepted. This requires a planUpdateWorkflows with status = COMPLETED
   *
   */
  ONBOARDING_COMPLETED = 'ONBOARDING_COMPLETED',
  /**
   * RTQ_COMPLETED will get the results from the most recent RTQ completion for this managedProduct & plan regardless of the progress of onboarding.
   * Once an RTQ has had scores recommended it will be available through this filter.
   *
   */
  RTQ_COMPLETED = 'RTQ_COMPLETED'
}

export enum QuestionnaireType {
  ONBOARDING = 'ONBOARDING',
  ONBOARDING_V2 = 'ONBOARDING_V2',
  SUITABILITY = 'SUITABILITY'
}

/** Rate Term Input */
export interface RateTermInput {
  /** Period of term range ie: 12 for display */
  displayPeriod: Scalars['Int'];
  /** Unit of measure for term range: ie: Month for display */
  displayUnit: RateUnitType;
  /** End of range */
  end?: InputMaybe<Scalars['Int']>;
  /** Start of range */
  start?: InputMaybe<Scalars['Int']>;
  /** Unit of measure for term range: ie: Month */
  unit?: InputMaybe<RateUnitType>;
}

export enum RateUnitType {
  DAY = 'DAY',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export interface RecommendationFilterInput {
  /**
   * List of eligible product variants used to filter model portfolios - BNS Specific
   * CAN NOT be used in combination with: selected_model_portfolios | series_ids
   *
   */
  eligibleProductVariants?: InputMaybe<Array<Scalars['String']>>;
  /** Maximum Risk score for the given account */
  riskScoreMax?: InputMaybe<Scalars['Int']>;
  /** Minimum Risk score for the given account */
  riskScoreMin?: InputMaybe<Scalars['Int']>;
  /** Can not be used in combination with: selected_model_portfolios | series_ids */
  scenarioId?: InputMaybe<Scalars['String']>;
  /**
   * List of Series Ids used to filter model portfolios
   * CAN NOT be used in combination with: eligible_product_variants | recommended_product_type | scenario_id
   *
   */
  seriesIds?: InputMaybe<Array<Scalars['Int']>>;
}

export enum RecommendationType {
  RISK_SCORE = 'RISK_SCORE',
  SUITABILITY = 'SUITABILITY'
}

export interface RegulatoryInformationInput {
  anotherBrokerEmployeeName?: InputMaybe<Scalars['String']>;
  anotherBrokerEmployeeRelationship?: InputMaybe<Scalars['String']>;
  anotherBrokerName?: InputMaybe<Scalars['String']>;
  controlType?: InputMaybe<ControlType>;
  countryOfOffice?: InputMaybe<Scalars['String']>;
  exchangeAddress?: InputMaybe<AddressInput>;
  exchangeAffiliationType?: InputMaybe<Scalars['String']>;
  exchangeEmployerName?: InputMaybe<Scalars['String']>;
  finraRelationship?: InputMaybe<RegulatoryRelationship>;
  gamblingIndustryParticipation?: InputMaybe<Scalars['Boolean']>;
  /** Does Client have a 3210 Affiliation Letter */
  hasAffiliationLetter?: InputMaybe<Scalars['Boolean']>;
  /** Family member of CSI representative */
  hasPartnerEmployeeConnection?: InputMaybe<Scalars['Boolean']>;
  /** Is the user/someone related is a senior political figure */
  hasSpfConnection?: InputMaybe<Scalars['Boolean']>;
  isAccreditedInvestor?: InputMaybe<Scalars['Boolean']>;
  isAssociatedWithOfacOrSdn?: InputMaybe<Scalars['Boolean']>;
  isAssociatedWithUsRegisteredInvestmentAdvisor?: InputMaybe<Scalars['Boolean']>;
  isDiplomaticEmployee?: InputMaybe<Scalars['Boolean']>;
  isEmployedByAccountBroker?: InputMaybe<Scalars['Boolean']>;
  isEmployedByAnotherBroker?: InputMaybe<Scalars['Boolean']>;
  /** If the user is affiliated with or employed by a stock exchange or member firm of an exchange, FINRA, or a municipal securities broker-dealer */
  isExchangeEmployed?: InputMaybe<Scalars['Boolean']>;
  isForeignGovernmentEmployee?: InputMaybe<Scalars['Boolean']>;
  isGovernmentEmployee?: InputMaybe<Scalars['Boolean']>;
  /** Is user an agent, officer, director, general partner, associated person or employee of this broker-dealer. */
  isPartnerEmployee?: InputMaybe<Scalars['Boolean']>;
  /** If the user/someone related is director, 10% shareholder, or policy-making officer of a publicly held company */
  isPhcSeniorOfficer?: InputMaybe<Scalars['Boolean']>;
  marijuanaBusinessesParticipation?: InputMaybe<Scalars['Boolean']>;
  nameOfSeniorPoliticalFigure?: InputMaybe<Scalars['String']>;
  phcRelationship?: InputMaybe<RegulatoryRelationship>;
  politicalTitle?: InputMaybe<Scalars['String']>;
  publiclyHeldCompanyName?: InputMaybe<Scalars['String']>;
  relatedEmployeeAnotherBrokerName?: InputMaybe<Scalars['String']>;
  relatedEmployeeBrokerName?: InputMaybe<Scalars['String']>;
  /** Name of person related to user employed with partner */
  relatedPartnerEmployeeName?: InputMaybe<Scalars['String']>;
  /** Relationship of user with person employed with partner */
  relatedPartnerEmployeeRelationship?: InputMaybe<Scalars['String']>;
  relatedToAnotherBrokerEmployee?: InputMaybe<Scalars['Boolean']>;
  relationship?: InputMaybe<Scalars['String']>;
  ticker?: InputMaybe<Scalars['String']>;
}

export enum RegulatoryRelationship {
  RELATED_PERSON = 'RELATED_PERSON',
  SELF_OR_ACCOUNT_OWNER = 'SELF_OR_ACCOUNT_OWNER'
}

export interface RelationshipInput {
  /** The unique identifier of the relationship */
  id?: InputMaybe<Scalars['ID']>;
  /** Is Per Stirpes */
  isPerStirpes?: InputMaybe<Scalars['Boolean']>;
  /** The type of relationship (i.e OWNER, Beneficiary, Trust) i.e. this mapping is account to party */
  name: RelationshipName;
  /** Names turstees when beneficiary relationship is of type Trust. */
  nameOfTrustees?: InputMaybe<Scalars['String']>;
  /** A number representing an arbitrary ordering between a list of relationships */
  order?: InputMaybe<Scalars['Int']>;
  /** The party linked to this relationship */
  party: PartyInput;
  /** A decimal representing the amount of ownership of the linked entity (i.e Financial account) */
  percentage?: InputMaybe<Scalars['Decimal']>;
  /** This is the relationship the account owner has with the party in this entity */
  relationshipType?: InputMaybe<RelationshipSubtype>;
}

export enum RelationshipName {
  ADVISOR = 'ADVISOR',
  AUTHORIZED_INDIVIDUAL = 'AUTHORIZED_INDIVIDUAL',
  BENEFICIAL_OWNER = 'BENEFICIAL_OWNER',
  BENEFICIARY = 'BENEFICIARY',
  BENEFICIARY_PRIMARY = 'BENEFICIARY_PRIMARY',
  BRANCH_ASSIGNMENT = 'BRANCH_ASSIGNMENT',
  COMPENSATION_ADVISOR = 'COMPENSATION_ADVISOR',
  CONTROL_PERSON = 'CONTROL_PERSON',
  CUSTODIAN = 'CUSTODIAN',
  DECEDENT = 'DECEDENT',
  DEPENDENT = 'DEPENDENT',
  EMPLOYEE = 'EMPLOYEE',
  FINANCIAL_ADVISER = 'FINANCIAL_ADVISER',
  FINANCIAL_ADVISOR = 'FINANCIAL_ADVISOR',
  FINANCIAL_ADVISOR_PRIMARY = 'FINANCIAL_ADVISOR_PRIMARY',
  HOUSEHOLD_MEMBER = 'HOUSEHOLD_MEMBER',
  OWNER = 'OWNER',
  OWNER_JOINT = 'OWNER_JOINT',
  OWNER_MINOR = 'OWNER_MINOR',
  OWNER_TRUST = 'OWNER_TRUST',
  POWER_OF_ATTORNEY = 'POWER_OF_ATTORNEY',
  POWER_OF_ATTORNEY_READ_ONLY = 'POWER_OF_ATTORNEY_READ_ONLY',
  PRIMARY_ADVISOR = 'PRIMARY_ADVISOR',
  PROTECTOR = 'PROTECTOR',
  SETTLOR = 'SETTLOR',
  THIRD_PARTY_OWNER = 'THIRD_PARTY_OWNER',
  TRUST = 'TRUST',
  TRUSTED_CONTACT = 'TRUSTED_CONTACT',
  TRUSTEE = 'TRUSTEE',
  TRUSTEE_AND_SETTLOR = 'TRUSTEE_AND_SETTLOR',
  UNKNOWN_RELATIONSHIP_NAME = 'UNKNOWN_RELATIONSHIP_NAME'
}

export enum RelationshipSubtype {
  AUNT = 'AUNT',
  BROTHER = 'BROTHER',
  CHARITY = 'CHARITY',
  CHILD = 'CHILD',
  DAUGHTER = 'DAUGHTER',
  DOMESTIC_PARTNER = 'DOMESTIC_PARTNER',
  FATHER = 'FATHER',
  FATHER_IN_LAW = 'FATHER_IN_LAW',
  FRIEND = 'FRIEND',
  GRANDDAUGHTER = 'GRANDDAUGHTER',
  GRANDFATHER = 'GRANDFATHER',
  GRANDMOTHER = 'GRANDMOTHER',
  GRANDSON = 'GRANDSON',
  MINOR = 'MINOR',
  MOTHER = 'MOTHER',
  MOTHER_IN_LAW = 'MOTHER_IN_LAW',
  NEIGHBOR = 'NEIGHBOR',
  NEPHEW = 'NEPHEW',
  NIECE = 'NIECE',
  NON_SPOUSE = 'NON_SPOUSE',
  OTHER = 'OTHER',
  SISTER = 'SISTER',
  SON = 'SON',
  SPOUSE = 'SPOUSE',
  TRUST = 'TRUST',
  UNCLE = 'UNCLE',
  UNKNOWN = 'UNKNOWN'
}

export interface RepCodeInput {
  isPrimary: Scalars['Boolean'];
  percentage: Scalars['Decimal'];
  repCode: Scalars['String'];
}

export interface RequestCloseInput {
  closeBrokerageAccount: Scalars['Boolean'];
  comments?: InputMaybe<Scalars['String']>;
  distributionReason?: InputMaybe<Scalars['String']>;
  federalTaxWithholdingCode?: InputMaybe<Scalars['String']>;
  federalTaxWithholdingPercentage?: InputMaybe<Scalars['Decimal']>;
  managedAccountId: Scalars['ID'];
  otherReason?: InputMaybe<Scalars['String']>;
  reason: CloseReason;
  scheduledAt?: InputMaybe<Scalars['DateTime']>;
  sellAssets: Scalars['Boolean'];
  stateTaxWithholdingCode?: InputMaybe<Scalars['String']>;
  stateTaxWithholdingPercentage?: InputMaybe<Scalars['Decimal']>;
  transactionId?: InputMaybe<Scalars['String']>;
  transferToFinancialAccountId?: InputMaybe<Scalars['ID']>;
  withdrawalGrossUp?: InputMaybe<Scalars['Boolean']>;
}

export enum ResponseContentTypes {
  JPG = 'JPG',
  PDF = 'PDF',
  PNG = 'PNG',
  ZIP = 'ZIP'
}

export enum RetakeSources {
  RETAKE_PRODUCT_SELECTION = 'RETAKE_PRODUCT_SELECTION',
  RETAKE_QUESTIONNAIRE = 'RETAKE_QUESTIONNAIRE',
  RETAKE_QUESTIONNAIRE_PRODUCT_SELECTION = 'RETAKE_QUESTIONNAIRE_PRODUCT_SELECTION',
  SWITCH_PRODUCT_SELECTION = 'SWITCH_PRODUCT_SELECTION',
  SWITCH_QUESTIONNAIRE = 'SWITCH_QUESTIONNAIRE',
  SWITCH_QUESTIONNAIRE_PRODUCT_SELECTION = 'SWITCH_QUESTIONNAIRE_PRODUCT_SELECTION'
}

export interface ReturnFactorsFilterInput {
  /** Glide path based on account type */
  glidePath: GlidePath;
  /** Investment horizon provided by the user. */
  investmentHorizon?: InputMaybe<Scalars['Int']>;
}

export enum ReviewCompletionType {
  CLIENT_DECLINED = 'CLIENT_DECLINED',
  CLIENT_IGNORED = 'CLIENT_IGNORED',
  CLIENT_UNREACHABLE = 'CLIENT_UNREACHABLE',
  CONDUCTED = 'CONDUCTED',
  /** Unknown review completion type */
  UNKNOWN = 'UNKNOWN'
}

export enum RolloverType {
  SOLICITED = 'SOLICITED',
  UNSOLICITED = 'UNSOLICITED'
}

export enum ScheduledTransferStatus {
  ACTIVE = 'ACTIVE',
  ACTIVE_AT_BROKERAGE = 'ACTIVE_AT_BROKERAGE',
  ATTEMPTING_TO_SUBMIT_TO_BROKERAGE = 'ATTEMPTING_TO_SUBMIT_TO_BROKERAGE',
  CANCELLED = 'CANCELLED',
  CASH_GENERATED = 'CASH_GENERATED',
  COMPLETED = 'COMPLETED',
  GENERAL_ERROR = 'GENERAL_ERROR',
  INSUFFICIENT_ASSETS = 'INSUFFICIENT_ASSETS',
  PENDING = 'PENDING',
  PENDING_CANCELLATION = 'PENDING_CANCELLATION',
  SUBMITTED = 'SUBMITTED',
  TEST = 'TEST',
  TRADES_SUBMITTED = 'TRADES_SUBMITTED',
  UNAUTHORIZED = 'UNAUTHORIZED'
}

/** The subset of selected model portfolio parameters used to filter model portfolios by. */
export interface SelectedModelPortfolioFilterInput {
  /** Identifier for the model portfolio used to identify the selected product. */
  modelPortfolioInternalId: Scalars['Int'];
  /** Series id used to identify the selected product. */
  modelPortfolioSeriesId: Scalars['Int'];
}

/** The parameters used to select a product using a sleeve */
export interface SelectedModelPortfolioInput {
  /** The live rate associated with the selected model portfolio. These rates are dynamic, client specific and must be verified as currently valid. */
  liveRates?: InputMaybe<Array<LiveRateInput>>;
  /** Identifier for the model portfolio used to identify the selected product. */
  modelPortfolioInternalId: Scalars['Int'];
  /** Series id used to identify the selected product. */
  modelPortfolioSeriesId: Scalars['Int'];
  /** Percentage of the account allocated to the selected model portfolio */
  percentage?: InputMaybe<Scalars['Decimal']>;
  /** The sleeve type. */
  sleeveType: SleeveType;
}

export enum Severity {
  CRITICAL = 'CRITICAL',
  MODERATE = 'MODERATE'
}

export enum SigningDocumentsField {
  CREATED = 'CREATED'
}

export interface SigningDocumentsSortInput {
  /** Fields that support sorting */
  field: SigningDocumentsField;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum SleeveType {
  INVESTMENT = 'INVESTMENT',
  UNMANAGED = 'UNMANAGED'
}

export interface SoftBlocksInput {
  estimatedFundingAmountCurrentValue?: InputMaybe<Scalars['Boolean']>;
  estimatedNetWorth?: InputMaybe<Scalars['Boolean']>;
  inServiceGreaterThanFifty?: InputMaybe<Scalars['Boolean']>;
  lessThanSeventyFive?: InputMaybe<Scalars['Boolean']>;
  lessThanThirtyThousand?: InputMaybe<Scalars['Boolean']>;
  outStandingLoanInService?: InputMaybe<Scalars['Boolean']>;
  outstandingLoanGreaterThanTwentyFiveAndCurrentValueGreaterThanTenThousand?: InputMaybe<Scalars['Boolean']>;
  sectionJustification?: InputMaybe<Scalars['Boolean']>;
  softBlockCustodialUtmaIrsGiftTax?: InputMaybe<Scalars['Boolean']>;
  softBlockJointAlert?: InputMaybe<Scalars['Boolean']>;
  softBlockMaximumInitialInvestmentForIncomeAtleastThirtyThousand?: InputMaybe<Scalars['Boolean']>;
  softBlockOverEighty?: InputMaybe<Scalars['Boolean']>;
  softBlockOverNinety?: InputMaybe<Scalars['Boolean']>;
  softBlockTaxableAccountInitialInvestmentOverSeventyFivePercent?: InputMaybe<Scalars['Boolean']>;
  softBlockTaxableAccountWithIncomeBelowThirtyThousand?: InputMaybe<Scalars['Boolean']>;
  tenantsInCommonPrompt?: InputMaybe<Scalars['Boolean']>;
}

export interface SortInput {
  /** Fields that support sorting */
  field: Field;
  /** Order type ASCENDING or DESCENDING */
  order: OrderType;
}

export enum SortOrder {
  ASCENDING = 'ASCENDING',
  DESCENDING = 'DESCENDING'
}

export enum SourceOfFundsType {
  ASSET_APPRECIATION = 'ASSET_APPRECIATION',
  BUSINESS_REVENUE = 'BUSINESS_REVENUE',
  EARNINGS = 'EARNINGS',
  GIFTS = 'GIFTS',
  INHERITANCE = 'INHERITANCE',
  INVESTMENT_PROCEEDS = 'INVESTMENT_PROCEEDS',
  LEGAL_OR_INSURANCE = 'LEGAL_OR_INSURANCE',
  LEGAL_SETTLEMENT = 'LEGAL_SETTLEMENT',
  OTHER = 'OTHER',
  PENSION_OR_IRA_OR_RETIREMENT_SAVINGS = 'PENSION_OR_IRA_OR_RETIREMENT_SAVINGS',
  ROLLOVER = 'ROLLOVER',
  SALE_OF_ASSETS = 'SALE_OF_ASSETS',
  SALE_OF_BUSINESS = 'SALE_OF_BUSINESS',
  SALE_OF_PRIMARY_REALESTATE = 'SALE_OF_PRIMARY_REALESTATE',
  SAVINGS_FROM_EARNING = 'SAVINGS_FROM_EARNING'
}

export enum SourceStatusTypes {
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  MFA_ERROR = 'MFA_ERROR',
  SIGFIG_ERROR = 'SIGFIG_ERROR',
  SUCCESS = 'SUCCESS',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export interface StringAttributesInput {
  /** The name of the property */
  name: Scalars['String'];
  /** The value of the property */
  value: Scalars['String'];
}

export enum SuitabilityOrderSubset {
  A_C_SHARE = 'A_C_SHARE',
  A_SHARE = 'A_SHARE',
  C_SHARE = 'C_SHARE',
  UNDEFINED = 'UNDEFINED',
  UNKNOWN = 'UNKNOWN'
}

export enum SurrenderType {
  FULL_ANNUITY_TRANSFER = 'FULL_ANNUITY_TRANSFER',
  PARTIAL_ANNUITY_TRANSFER = 'PARTIAL_ANNUITY_TRANSFER',
  PENALTY_FREE_SURRENDER = 'PENALTY_FREE_SURRENDER'
}

export enum SuspensionType {
  PARTNER = 'PARTNER',
  TRADER = 'TRADER'
}

export enum TaskStatus {
  /** Task is completed */
  COMPLETED = 'COMPLETED',
  /** Task has expired and cannot be revoked */
  EXPIRED = 'EXPIRED',
  /** Task is pending */
  PENDING = 'PENDING'
}

export enum TaxStatus {
  CERTIFIED_NO_WITHHOLDING = 'CERTIFIED_NO_WITHHOLDING',
  EXEMPT_ACCOUNT = 'EXEMPT_ACCOUNT',
  GOVT_MANDATED_WITHHOLDING = 'GOVT_MANDATED_WITHHOLDING',
  NOT_CERTIFIED_WITHHOLD = 'NOT_CERTIFIED_WITHHOLD',
  UNKNOWN = 'UNKNOWN',
  W9_CERTIFIED_NO_WITHHOLDING = 'W9_CERTIFIED_NO_WITHHOLDING'
}

export interface TradingSuspensionFilter {
  /** Tags of suspension (if any of these tags matches) */
  suspensionTags?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Type of suspension */
  suspensionTypes?: InputMaybe<Array<InputMaybe<SuspensionType>>>;
}

export enum TransferDirection {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum TransferEntity {
  ANNUITY = 'ANNUITY',
  ASSET = 'ASSET',
  CASH = 'CASH'
}

export enum TransferFrequency {
  BIMONTHLY = 'BIMONTHLY',
  BIWEEKLY = 'BIWEEKLY',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
  WEEKLY = 'WEEKLY'
}

export enum TransferFrequencyType {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING'
}

export enum TransferMethod {
  ACAT = 'ACAT',
  INTERNAL = 'INTERNAL'
}

export enum TransferMethodSubType {
  DEFAULT = 'DEFAULT',
  JOURNAL = 'JOURNAL'
}

export enum TransferType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum TrustIdentificationType {
  SSN = 'SSN',
  TIN = 'TIN'
}

export interface TrustInformationInput {
  canTrusteesModifyTrust?: InputMaybe<Scalars['String']>;
  eachTrusteeAction?: InputMaybe<Scalars['String']>;
  isTrusteeGrantor?: InputMaybe<Scalars['Boolean']>;
  numberOfTrustees?: InputMaybe<Scalars['Int']>;
  otherSettlorTrustorOrGrantor?: InputMaybe<Scalars['String']>;
  otherTrusteeAction?: InputMaybe<Scalars['String']>;
  revokerFirstName?: InputMaybe<Scalars['String']>;
  revokerLastName?: InputMaybe<Scalars['String']>;
  revokerMiddleName?: InputMaybe<Scalars['String']>;
  settlorTrustorOrGrantor?: InputMaybe<Scalars['String']>;
  trustAgreementDate?: InputMaybe<Scalars['Date']>;
  trustGoverningState?: InputMaybe<Scalars['String']>;
  trustIdentificationNumber?: InputMaybe<Scalars['String']>;
  trustIdentificationType?: InputMaybe<TrustIdentificationType>;
  trustName?: InputMaybe<Scalars['String']>;
  trustProfileType: TrustProfileType;
  trustType?: InputMaybe<TrustType>;
  whoCanModifyTrust?: InputMaybe<Scalars['String']>;
}

export enum TrustProfileType {
  NON_TRUSTEE_GRANTOR = 'NON_TRUSTEE_GRANTOR',
  TRUST = 'TRUST',
  TRUSTEE = 'TRUSTEE'
}

export enum TrustType {
  IRREVOCABLE = 'IRREVOCABLE',
  REVOCABLE = 'REVOCABLE'
}

export interface UpdateBankAccountInput {
  accountNumber: Scalars['String'];
  financialInstitution: Scalars['String'];
  nameOnBankAccount: Scalars['String'];
  routingNumber: Scalars['String'];
  type: BankAccountType;
}

export interface UpdateFinancialAccountAssociationVerificationRequestInput {
  /** Financial account association verification request identifier */
  id: Scalars['ID'];
  status: FinancialAccountAssociationVerificationStatus;
}

export interface UpdateFlagInput {
  /** The identifier used to represent a flag */
  id: Scalars['Int'];
  /** The status of the flag */
  status: FlagStatus;
}

export interface UpdatePlanInput {
  /** Defaults true. Denotes if the user accepts or rejects the investment plan */
  accept?: InputMaybe<Scalars['Boolean']>;
  /** The identifier that represents a managed account */
  managedId?: InputMaybe<Scalars['ID']>;
  /** Denotes the current plan model portfolio identifier */
  modelPortfolioIdentifier?: InputMaybe<Scalars['Int']>;
  /** Denotes the current plan model portfolio series */
  modelPortfolioSeriesId?: InputMaybe<Scalars['Int']>;
  /** The identifier that represents a managed account plan */
  planId?: InputMaybe<Scalars['String']>;
  planUpdateWorkflowId?: InputMaybe<Scalars['ID']>;
}

export interface UpdatePortfolioInput {
  financialAccountId: Scalars['ID'];
  name: Scalars['String'];
}

export interface UpdateTransferInput {
  brokerageTransferId?: InputMaybe<Scalars['String']>;
  /** This represents the managedProductId the transfer was created under. */
  financialAccountId: Scalars['ID'];
  frequency: TransferFrequency;
  pin?: InputMaybe<Scalars['String']>;
  status: ScheduledTransferStatus;
  /** The id for the cash transfer to cancel. */
  transferId: Scalars['ID'];
  type: TransferType;
}

export enum UpdateWorkflowStatus {
  /** UpdateWorkflow Discarded. */
  CANCELLED = 'CANCELLED',
  /** UpdateWorkflow is Complete. */
  COMPLETED = 'COMPLETED',
  /** Documents associatied with UpdateWorkflow have been Prepared. */
  DOCS_PREPARED = 'DOCS_PREPARED',
  /** Documents associatied with UpdateWorkflow have been Signed. */
  DOCS_SIGNED = 'DOCS_SIGNED',
  /** Update workflow is pending. */
  PENDING = 'PENDING',
  /** Signing Document Failed. */
  SIGNATURE_FAILED = 'SIGNATURE_FAILED',
  /** UpdateWorkflow is staged. */
  STAGED = 'STAGED'
}

export enum UserNoteEntityType {
  ASSET_DEPOSIT = 'ASSET_DEPOSIT',
  BANK_ACCOUNT_ASSOCIATION_VERIFICATION_REQUEST = 'BANK_ACCOUNT_ASSOCIATION_VERIFICATION_REQUEST',
  CASH_DEPOSIT = 'CASH_DEPOSIT',
  CASH_WITHDRAWAL = 'CASH_WITHDRAWAL',
  CLOSE_WORKFLOW = 'CLOSE_WORKFLOW',
  COPILOT_PORTFOLIO = 'COPILOT_PORTFOLIO',
  FAILURE = 'FAILURE',
  FUNDING_REVIEW = 'FUNDING_REVIEW',
  RECURRING_CASH_DEPOSIT = 'RECURRING_CASH_DEPOSIT',
  RECURRING_CASH_WITHDRAWAL = 'RECURRING_CASH_WITHDRAWAL',
  RESTRICTION = 'RESTRICTION',
  SIGNING_DOCUMENT = 'SIGNING_DOCUMENT',
  TRADING_SUSPENSION = 'TRADING_SUSPENSION'
}

export enum UserType {
  ASSISTANT = 'ASSISTANT',
  CLIENT = 'CLIENT',
  FA = 'FA',
  OTHER = 'OTHER',
  UNKNOWN = 'UNKNOWN'
}

export interface ValidateCashTransferInput {
  accountType: FinancialAccountType;
  bankAccountId: Scalars['ID'];
  cashAmount: Scalars['Decimal'];
  /** This is the obfuscated id of the portfolio on which managed product is applied */
  financialAccountId: Scalars['String'];
  frequency: TransferFrequency;
  managedId: Scalars['ID'];
  scheduledAt?: InputMaybe<Scalars['Date']>;
  type: TransferType;
}

export interface WealthInput {
  annualIncome?: InputMaybe<Scalars['String']>;
  annualRecurringExpenses?: InputMaybe<Scalars['String']>;
  cash?: InputMaybe<Scalars['String']>;
  hasEmergencyFunds?: InputMaybe<Scalars['Boolean']>;
  liabilities?: InputMaybe<Scalars['String']>;
  liquidAssets?: InputMaybe<Scalars['String']>;
  netWorth?: InputMaybe<Scalars['String']>;
  nonLiquidAssets?: InputMaybe<Scalars['String']>;
  numberOfDependents?: InputMaybe<Scalars['Int']>;
  otherSourceOfInitialFunds?: InputMaybe<Scalars['String']>;
  sourceOfInitialFunds?: InputMaybe<Scalars['String']>;
  specialExpenses?: InputMaybe<Scalars['String']>;
  taxBracket?: InputMaybe<Scalars['String']>;
}
