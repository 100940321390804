import './index.css';

import * as Sentry from '@sentry/react';
import React, { StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom';

import {
  CoreConfigProvider,
  createTheme,
  initializeNewRelic,
  LiveAnnouncer,
  PostHogProvider,
  sfPosthog,
  ThemeProvider,
} from '@sigfig/digital-wealth-core';

import App from './App';
import { PageLoading } from './components/Loading';
import config, { coreConfigs, isProd, isSqa9 } from './config';
import { AccountNumberProvider } from './contexts/AccountNumber';
import { AppProvider } from './contexts/App';
import * as serviceWorker from './serviceWorker';
import { defaultTheme } from './theme';

initializeNewRelic(config.newRelic.licenseKey, config.newRelic.applicationId);

type LogLevel = 'debug' | 'error' | 'info' | 'warn';
type PostHogSentryLogLevel = Exclude<LogLevel, 'warn'> | 'warning';

const getLogLevels = (): Array<LogLevel> => {
  if (isProd()) {
    return ['error'];
  }
  if (process.env.NODE_ENV === 'development' || isSqa9()) {
    // SQA9 is a load testing environment, so turn off logging.
    return [];
  }
  return ['warn', 'error'];
};

const getPosthogSentryIntegrationLogLevels = (): Array<PostHogSentryLogLevel> => {
  return getLogLevels().map(level => (level === 'warn' ? 'warning' : level));
};

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.captureConsoleIntegration({
      levels: getLogLevels(), // TODO: revisit this after warnings are cleaned up in https://sigfig.atlassian.net/browse/ANR-7195
    }),
    sfPosthog.sentryIntegration({
      organization: config.sentry.orgId,
      projectId: config.sentry.projectId,
      prefix: config.sentry.sentryInstance,
      severityAllowList: getPosthogSentryIntegrationLogLevels(),
    }),
  ],
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <StrictMode>
    <Sentry.ErrorBoundary>
      <PostHogProvider apiKey={config.posthog.token} options={sfPosthog.DEFAULT_CONFIG}>
        <ThemeProvider theme={createTheme(defaultTheme)}>
          <CoreConfigProvider value={coreConfigs}>
            <LiveAnnouncer>
              <AppProvider>
                <AccountNumberProvider>
                  <Suspense fallback={<PageLoading />}>
                    <App />
                  </Suspense>
                </AccountNumberProvider>
              </AppProvider>
            </LiveAnnouncer>
          </CoreConfigProvider>
        </ThemeProvider>
      </PostHogProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
