import { createSearchParams, generatePath } from 'react-router-dom';

import { RetakeContext } from './RetakeWrapper/util';

const generateAbsolutePath: typeof generatePath = (...args) => `/${generatePath(...args)}`;

export type AccountActionSource = 'dashboard' | 'accountDetails';
export type AccountActionParams = {
  certificateNumber?: string;
  managedProductId: string;
  retakeContext: RetakeContext;
  source: AccountActionSource;
};
export enum PageRoute {
  AccountAlreadyInProgress = 'account-already-in-progress/:managedProductId',
  AccountDetails = 'account-summary/details/:managedProductId',
  AccountSummary = 'account-summary',
  ExpiredProfile = 'expired/:retakeContext/:managedProductId',
  Goals = 'goals',
  GoalsCreate = 'goals/create',
  GoalsEdit = 'goals/:goalId',
  Onboarding = 'onboarding',
  OnboardingStep = 'onboarding/:onboardingStep',
  Retake = 'retake/:retakeContext/:managedProductId',
}

const getSearchParams = (paramValuePair?: Record<string, string | undefined>): string => {
  const paramsWithValue = Object.entries(paramValuePair ?? {}).reduce(
    (acc, [k, v]) => (v ? { ...acc, [k]: v } : acc),
    {},
  );
  return Object.keys(paramsWithValue).length ? `?${createSearchParams(paramsWithValue)}` : '';
};

export const routes = {
  accountAlreadyInProgress: (managedProductId: string, preselectedGoalId?: string): string =>
    generateAbsolutePath(PageRoute.AccountAlreadyInProgress, { managedProductId }) +
    getSearchParams({ preselectedGoalId }),
  accountDetails: (managedProductId: string): string =>
    generateAbsolutePath(PageRoute.AccountDetails, { managedProductId }),
  accountSummary: (): string => generateAbsolutePath(PageRoute.AccountSummary),
  expiredProfile: (managedProductId: string, retakeContext: RetakeContext): string =>
    generateAbsolutePath(PageRoute.ExpiredProfile, { managedProductId, retakeContext }),
  goals: (): string => generateAbsolutePath(PageRoute.Goals),
  goalsCreate: (goalObjectiveKey?: string): string =>
    generateAbsolutePath(PageRoute.GoalsCreate) + getSearchParams({ goalObjectiveKey }),
  goalsEdit: (goalId: string): string => generateAbsolutePath(PageRoute.GoalsEdit, { goalId }),
  onboarding: (preselectedGoalId?: string): string =>
    generateAbsolutePath(PageRoute.Onboarding) + getSearchParams({ preselectedGoalId }),
  onboardingStep: (onboardingStep: string, preselectedGoalId?: string): string =>
    generateAbsolutePath(PageRoute.OnboardingStep, { onboardingStep }) + getSearchParams({ preselectedGoalId }),
  retake: (retakeContext: RetakeContext, managedProductId: string): string =>
    generateAbsolutePath(PageRoute.Retake, { managedProductId, retakeContext }),
};
