import {
  MutationHookOptions,
  MutationTuple,
  QueryHookOptions,
  QueryResult,
  QueryTuple,
  useLazySymphonyQuery,
  useSymphonyMutation,
  useSymphonyQuery,
} from '@sigfig/digital-wealth-core';

import { getTag } from '../../../utils/symphony/portfolio';
import { PortfolioTags, ProductType, ProductVariant } from '../../../utils/symphony/types';

import {
  GetModelPortfolios,
  GetModelPortfolios_managedProduct_planUpdateWorkflows_selectedModelPortfolios,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_fees,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_fundAllocations,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_fundAllocations_assetClassAllocations,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_liveRate,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_localizedProperties,
  GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_performance,
  GetModelPortfoliosVariables,
} from './__generated__/getModelPortfolios.v2';
import { SaveProductSelection, SaveProductSelectionVariables } from './__generated__/saveProductSelection.v2';

export enum AssetClasses {
  CANADIAN_EQUITY = 'CANADIAN_EQUITY',
  CASH_EQUIVALENT = 'CASH_EQUIVALENT',
  FIXED_INCOME = 'FIXED_INCOME',
  FOREIGN_EQUITY = 'FOREIGN_EQUITY',
  OTHER = 'OTHER',
}

export type ModelPortfolio = GetModelPortfolios_modelPortfoliosV2_modelPortfolios;
export type ModelPortfolioAllocations = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations;
export type ModelPortfolioAllocationSecurityAllocation = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities;
export type ModelPortfolioAllocationSecurity = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security;
export type ModelPortfolioAllocationFees = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_fees;
export type ModelPortfolioAllocationsLiveRate = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_liveRate;
export type ModelPortfolioAllocationPerformance = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_performance;
export type ModelPortfolioAssetClassAllocations = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_fundAllocations_assetClassAllocations;
export type ModelPortfolioLocalizedProperties = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_localizedProperties;
export type ModelPortfolioFundAllocation = GetModelPortfolios_modelPortfoliosV2_modelPortfolios_guidance_diversification_assets_allocations_recommendedSecurities_security_fundAllocations;
export type SelectedModelPortfolios = GetModelPortfolios_managedProduct_planUpdateWorkflows_selectedModelPortfolios;

/**
 * The Aria series (that is composed of build/defend/pay funds) have IS_ARIA tag with seriesBaseName like "ARIAEQU"
 * The Aria individual funds do not have IS_ARIA, but they all have seriesBaseName of "ARIA Funds".
 * As of Project Pivot PDZ-229, neither the Aria series nor funds are offered or recommended.
 * They may appear in VAP but are unselectable with a message.
 */
export const isAria = (portfolio: Parameters<typeof getTag>[1], seriesBaseName: string | null): boolean =>
  seriesBaseName === 'ARIA Funds' || !!getTag(PortfolioTags.IS_ARIA_TAG, portfolio)?.value;

export const getProductType = (portfolio: Parameters<typeof getTag>[1]): ProductType | undefined =>
  getTag(PortfolioTags.PRODUCT_TYPE_TAG, portfolio)?.value;

/**
 * Given a model portfolio, return the `BASE_PRODUCT` tag value or `undefined` if the tag does not exist.
 * @param portfolio - The model portfolio to extract the tag from.
 * @returns The tag value or undefined if the tag is no present.
 */
export const getBaseProduct = (portfolio: Parameters<typeof getTag>[1]): string | undefined =>
  getTag(PortfolioTags.BASE_PRODUCT_TAG, portfolio)?.value;

/**
 * Given a model portfolio, return the `PRODUCT_VARIANT` tag value or `null` if the tag does not exist.
 * @param portfolio - The model portfolio to extract the tag from.
 * @returns The tag value or null if the tag is no present.
 */
export const getProductVariant = (portfolio: Parameters<typeof getTag>[1]): ProductVariant | null =>
  getTag(PortfolioTags.PRODUCT_VARIANT_TAG, portfolio)?.value ?? null;

import * as query from './getModelPortfolios.gql';
import * as mutation from './saveProductSelection.gql';

export const useGetModelPortfolios = (
  options?: QueryHookOptions<GetModelPortfolios, GetModelPortfoliosVariables>,
): QueryResult<GetModelPortfolios, GetModelPortfoliosVariables> => {
  return useSymphonyQuery(query.GetModelPortfolios, options);
};

export const useLazyGetModelPortfolios = (
  options?: QueryHookOptions<GetModelPortfolios, GetModelPortfoliosVariables>,
): QueryTuple<GetModelPortfolios, GetModelPortfoliosVariables> => {
  return useLazySymphonyQuery(query.GetModelPortfolios, options);
};

export const useSaveProductSelection = (
  options?: MutationHookOptions<SaveProductSelection, SaveProductSelectionVariables>,
): MutationTuple<SaveProductSelection, SaveProductSelectionVariables> => {
  return useSymphonyMutation(mutation.SaveProductSelection, options);
};
